import { AnalyticsEvents } from '@ikon-web/event-shared';
import { Notification, NotificationState } from '@ikon-web/notification-types';
import { Profile, Room, RoomTemplatePublic, SpacePublic } from '@ikon-web/space-types';
import { IkonClientConfiguration } from '@ikon-web/utils';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE_AUTH_TOKEN } from '../constants';
import { baseUrl } from '../utils/api.utils';
import { isIframe } from '../utils/iframe.utils';

export interface ResultsResponse<T> {
  results: T[];
  previousCursor: string;
  nextCursor: string;
  count: number;
  totalCount: number;
}

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: 'include',
  prepareHeaders: (headers) => {
    if (!isIframe) return headers;
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
const baseQueryWithLogout: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // There is no auth context accessible here, so we need to redirect to the logout page
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);

    if (window.location.pathname !== '/auth/login') window.location.replace('/auth/login');
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithLogout,
  tagTypes: ['Invitation', 'Notification', 'Profile', 'Room', 'RoomTemplate', 'Space', 'User'],
  endpoints: (builder) => ({
    consumeInvitation: builder.mutation<void, string>({
      query: (code) => ({
        method: 'POST',
        url: `/meet/1/invitations/consume`,
        body: { code },
      }),
    }),

    getNotifications: builder.query<ResultsResponse<Notification>, { space: string; user: string; limit?: number }>({
      query: (params) => ({ url: `/meet/1/notifications`, params }),
      providesTags: ['Notification'],
    }),
    updateNotificationStates: builder.mutation<void, { notifications: string[]; state: NotificationState }>({
      query: (body) => ({ method: 'POST', url: `/meet/1/notifications/state`, body }),
      invalidatesTags: ['Notification'],
    }),

    getProfileByMe: builder.query<Profile, { space: string }>({
      query: (params) => ({ url: `meet/1/profiles/me`, params }),
      providesTags: ['Profile'],
    }),
    getProfileByUser: builder.query<Profile, { space: string; user: string }>({
      query: (params) => ({ url: `meet/1/profiles/user`, params }),
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation<Profile, { id: string; form: Partial<Profile> }>({
      query: (data) => ({ method: 'PATCH', url: `meet/1/profiles/${data.id}`, body: data.form }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(api.util.upsertQueryData('getProfileByMe', { space: data.space }, data));
      },
    }),

    getRooms: builder.query<ResultsResponse<Room>, { space: string; embeddedSpaceTemplate?: string }>({
      query: (params) => ({ url: `/meet/1/rooms`, params: { ...params, scope: 'my', limit: 50 } }),
      providesTags: ['Room'],
    }),
    getRoomByCode: builder.query<Room, string>({
      query: (code) => `/meet/1/rooms/code/${code}`,
      providesTags: ['Room'],
    }),
    createRoom: builder.mutation<Room, { roomTemplate?: string; mode?: string; name?: string; ikonServerHost?: string; deviceId: string }>({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/rooms`,
        body,
      }),
      invalidatesTags: ['Room'],
    }),
    updateRoom: builder.mutation<Room, { code: string; name?: string }>({
      query: ({ code, ...body }) => ({
        method: 'PATCH',
        url: `/meet/1/rooms/code/${code}`,
        body,
      }),
    }),
    joinRoom: builder.mutation<Room, { code: string; deviceId: string }>({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/rooms/join`,
        body,
      }),
    }),
    connectRoom: builder.mutation<
      { state: string; configuration: IkonClientConfiguration },
      {
        code: string;
        contextType: number;
        userType: number;
        payloadType: number;
        description: string;
        deviceId: string;
        productId: string;
        versionId: string;
        installId: string;
        locale?: string;
        opcodeGroupsFromServer: number;
        opcodeGroupsToServer: number;
        protocolVersion: number;
        hasInput?: boolean;
        embeddedSpaceId?: string;
      }
    >({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/rooms/connect`,
        body,
      }),
    }),

    getRoomTemplatesBySpace: builder.query<ResultsResponse<RoomTemplatePublic>, string>({
      query: (id) => ({ url: `meet/1/room-templates`, params: { space: id } }),
      providesTags: ['RoomTemplate'],
    }),

    getRoomTemplateById: builder.query<RoomTemplatePublic, string>({
      query: (id) => ({ url: `meet/1/room-templates/${id}` }),
      providesTags: ['RoomTemplate'],
    }),

    getSpaceByDomain: builder.query<SpacePublic, string>({
      query: (domain) => `meet/1/spaces/domain/${domain}`,
      providesTags: ['Space'],
    }),

    event: builder.mutation<void, { event: AnalyticsEvents; space?: string }>({
      query: (body) => ({
        method: 'POST',
        url: `/event/1/events/analytics/json`,
        body,
      }),
    }),
  }),
});
