import { encode, decode } from '@msgpack/msgpack';

export const MinimumHeaderLength = 27;
export const ProtocolVersion = 1;

export interface MessageHeaders {
  length: number;
  opcode: Opcode;
  senderId: number;
  trackId: number;
  sequenceId: number;
  targetIdCount: number;
  payloadVersion: number;
  payloadType: PayloadType;
  flags: number;
  targetIds: number[];
}

export function readMessageHeaders(raw: ArrayBuffer): MessageHeaders {
  const dataView = new DataView(raw);
  const length = dataView.getUint32(0, true);
  const opcode = dataView.getUint32(4, true) as Opcode;
  const senderId = dataView.getUint32(8, true);
  const trackId = dataView.getUint32(12, true);
  const sequenceId = dataView.getUint32(16, true);
  const targetIdCount = dataView.getUint32(20, true);
  const payloadVersion = dataView.getUint8(24);
  const payloadType = dataView.getUint8(25) as PayloadType;
  const flags = dataView.getUint8(26);

  let offset = MinimumHeaderLength;
  const targetIds = [];

  for (let i = 0; i < targetIdCount; i++) {
    targetIds.push(dataView.getUint32(offset, true));
    offset += 4;
  }

  return {
    length,
    opcode,
    senderId,
    trackId,
    sequenceId,
    targetIdCount,
    payloadVersion,
    payloadType,
    flags,
    targetIds,
  };
}

function readMessagePayload(raw: ArrayBuffer): ArrayBuffer {
  const dataView = new DataView(raw);
  const targetIdCount = dataView.getUint32(20, true);
  return raw.slice(MinimumHeaderLength + targetIdCount * 4);
}

function createMessage(
  senderId: number,
  opcode: number,
  payloadBytes: Uint8Array,
  payloadVersion: number,
  payloadType: PayloadType,
  trackId: number,
  sequenceId: number,
  flags: number,
  targetIds: number[]
): Uint8Array {
  const headerSize = MinimumHeaderLength + targetIds.length * 4;
  const totalSize = headerSize + payloadBytes.length;

  const header = new Uint8Array(headerSize);
  const dataView = new DataView(header.buffer);

  dataView.setUint32(0, totalSize, true);
  dataView.setUint32(4, opcode, true);
  dataView.setUint32(8, senderId, true);
  dataView.setUint32(12, trackId, true);
  dataView.setUint32(16, sequenceId, true);
  dataView.setUint32(20, targetIds.length, true);
  dataView.setUint8(24, payloadVersion);
  dataView.setUint8(25, payloadType);
  dataView.setUint8(26, flags);
  
  let offset = MinimumHeaderLength;

  for (let i = 0; i < targetIds.length; i++) {
    dataView.setUint32(offset, targetIds[i], true);
    offset += 4;
  }

  const message = new Uint8Array(totalSize);
  message.set(header, 0);
  message.set(payloadBytes, headerSize);

  return message;
}

export enum UIStreamCategories {
  Chat = 'Chat',
  Collapsed = 'Collapsed',
  DebugOverlay = 'DebugOverlay',
  Header = 'Header',
  Menu = 'Menu',
  Overlay = 'Overlay',
  Preview = 'Preview',
  SecondScreen = 'SecondScreen',
}

export enum UIElementLabels {
  Blur = 'blur',
  ChatMessage = 'chat-message',
  Disabled = 'disabled',
  ImageAvatar = 'image-avatar',
  Markdown = 'markdown',
  SizeExtraSmall = 'size-extra-small',
  SizeFitContent = 'size-fit-content',
  SizeFullWidth = 'size-full-width',
  SizeLarge = 'size-large',
  SizeMedium = 'size-medium',
  SizeSmall = 'size-small',
  Wrap = 'wrap',
}

export enum AudioCodec {
  Unknown = 0,
  Opus = 1,
  Mp3 = 2,
  RawPcm16 = 3,
}

export enum ContextType {
  Unknown = 0,
  Backend = 2,
  Server = 4,
  Plugin = 8,
  Browser = 16,
}

export enum EntrypointType {
  None = 0,
  WebSocket = 1,
  WebSocketProxy = 2,
  WebTransport = 4,
  WebTransportProxy = 8,
  Tcp = 16,
  TcpProxy = 32,
  Http = 64,
}

export enum FunctionKeyType {
  None = 0,
  String = 1,
  Int = 2,
}

export enum FunctionValueType {
  None = 0,
  Bool = 1,
  Int = 2,
  Float = 3,
  String = 4,
  Json = 5,
}

export enum LogFilter {
  None = 0,
  Critical = 288,
  Error = 304,
  Warning = 312,
  Info = 508,
  Debug = 510,
  Trace = 511,
}

export enum LogType {
  None = 0,
  Trace = 1,
  Debug = 2,
  Info = 4,
  Warning = 8,
  Error = 16,
  Critical = 32,
  Event = 64,
  Usage = 128,
  Exception = 256,
}

export enum MessageFlag {
  None = 0,
  SendBackToSender = 1,
  Delayable = 2,
  SendToUser = 4,
}

export enum Opcode {
  NONE = 0,
  CONSTANT_GROUP_BITS = 15,
  CONSTANT_GROUP_OFFSET = 16,
  GROUP_CORE = 65536,
  CORE_AUTH_RESPONSE = 65537,
  CORE_AUTH_TICKET = 65538,
  CORE_GLOBAL_STATE = 65539,
  CORE_ON_SERVER_STATUS_PING = 65540,
  CORE_ON_USER_JOINED = 65541,
  CORE_ON_USER_LEFT = 65542,
  CORE_ON_CLIENT_JOINED = 65543,
  CORE_ON_CLIENT_LEFT = 65544,
  CORE_ON_SERVER_STARTED = 65545,
  CORE_ON_SERVER_STOPPED = 65546,
  CORE_ON_SERVER_STOPPING = 65547,
  CORE_ON_CLIENT_READY = 65548,
  CORE_CLIENT_READY = 65549,
  CORE_SERVER_INIT = 65550,
  CORE_ON_PLUGIN_RELOADED = 65551,
  CORE_SERVER_START = 65552,
  CORE_SERVER_STOP = 65553,
  CORE_ON_HOSTED_SERVER_EXIT = 65554,
  GROUP_KEEPALIVE = 131072,
  KEEPALIVE_REQUEST = 131073,
  KEEPALIVE_RESPONSE = 131074,
  GROUP_EVENTS = 262144,
  EVENTS_PROFILE_UPDATE = 262145,
  EVENTS_CHANNEL_COMPLETE = 262146,
  GROUP_ANALYTICS = 524288,
  ANALYTICS_LOGS = 524289,
  ANALYTICS_EVENTS = 524290,
  ANALYTICS_USAGES = 524291,
  ANALYTICS_USAGE = 524292,
  ANALYTICS_SPECIAL_LOG = 524293,
  ANALYTICS_PROCESSING_UPDATE = 524294,
  GROUP_ACTIONS = 1048576,
  ACTION_CALL = 1048577,
  ACTION_ACTIVE = 1048578,
  ACTION_TEXT_OUTPUT = 1048579,
  ACTION_TEXT_OUTPUT_DELTA = 1048580,
  ACTION_TEXT_OUTPUT_DELTA_FULL = 1048581,
  ACTION_SET_STATE = 1048582,
  ACTION_TAP = 1048583,
  ACTION_PAN = 1048584,
  ACTION_ZOOM = 1048585,
  ACTION_FILE_UPLOAD_BEGIN = 1048586,
  ACTION_FILE_UPLOAD_DATA = 1048587,
  ACTION_FILE_UPLOAD_ACK = 1048588,
  ACTION_FILE_UPLOAD_END = 1048589,
  ACTION_FILE_UPLOAD_RESULT = 1048590,
  ACTION_OPEN_CHANNEL = 1048591,
  ACTION_OPEN_EXTERNAL_URL = 1048592,
  ACTION_UI_OPEN_VIEW = 1048593,
  ACTION_UI_CLOSE_VIEW = 1048594,
  ACTION_UI_BLOCKING_BEGIN = 1048595,
  ACTION_UI_BLOCKING_END = 1048596,
  ACTION_UI_UPDATE_TEXT_DELTA = 1048597,
  ACTION_UI_DELETE_CONTAINER = 1048598,
  ACTION_UPDATE_GFX_SHADER = 1048599,
  ACTION_FUNCTION_REGISTER = 1048600,
  ACTION_FUNCTION_CALL = 1048601,
  ACTION_FUNCTION_CALL_RESULT = 1048602,
  ACTION_GENERATE_ANSWER = 1048603,
  ACTION_REGENERATE_ANSWER = 1048604,
  ACTION_CLEAR_MESSAGE_HISTORY = 1048605,
  ACTION_CLEAR_STATE = 1048606,
  ACTION_RELOAD_CHANNELS = 1048607,
  ACTION_RELOAD_PROFILE = 1048608,
  ACTION_CLASSIFICATION_RESULT = 1048609,
  ACTION_AUDIO_STOP = 1048610,
  ACTION_CALL_TEXT = 1048611,
  ACTION_RELOAD_APPLICATION = 1048612,
  ACTION_CANCEL_GENERATION = 1048613,
  ACTION_UI_SET_CONTAINER_STABLE = 1048614,
  ACTION_SPEECH_RECOGNIZED = 1048615,
  ACTION_CALL_RESULT = 1048616,
  GROUP_UI = 2097152,
  UI_STREAM_BEGIN = 2097153,
  UI_STREAM_END = 2097154,
  UI_CONTAINER_BEGIN = 2097155,
  UI_CONTAINER_END = 2097156,
  UI_SECTION_BEGIN = 2097157,
  UI_SECTION_END = 2097158,
  UI_LIST_BEGIN = 2097159,
  UI_LIST_ITEM = 2097160,
  UI_LIST_END = 2097161,
  UI_TEXT = 2097162,
  UI_HEADER = 2097163,
  UI_SEPARATOR = 2097164,
  UI_BUTTON = 2097165,
  UI_ICON_BUTTON = 2097166,
  UI_IMAGE = 2097167,
  UI_FILE = 2097168,
  UI_BADGE = 2097169,
  UI_CONTENT_LINK = 2097170,
  UI_MAP = 2097171,
  UI_VEGA_CHART = 2097172,
  UI_ICON = 2097173,
  UI_FILE_UPLOAD_SECTION_BEGIN = 2097174,
  UI_FILE_UPLOAD_SECTION_END = 2097175,
  UI_MATERIAL_SYMBOL = 2097176,
  UI_BUTTON_BEGIN = 2097177,
  UI_BUTTON_END = 2097178,
  UI_CONTAINER_DELETE = 2097179,
  UI_INPUT_TEXT = 2097180,
  UI_PROGRESS_BAR = 2097181,
  UI_UPDATE_BEGIN = 2097182,
  UI_UPDATE_END = 2097183,
  UI_AUTOCOMPLETE = 2097184,
  UI_CHECKBOX = 2097185,
  UI_QS = 2097186,
  UI_ELEMENT = 2097187,
  UI_STYLES = 2097188,
  GROUP_AUDIO = 4194304,
  AUDIO_STREAM_BEGIN = 4194305,
  AUDIO_STREAM_END = 4194306,
  AUDIO_FRAME = 4194307,
  AUDIO_FRAME_VOLUME = 4194308,
  GROUP_VIDEO = 8388608,
  VIDEO_STREAM_BEGIN = 8388609,
  VIDEO_STREAM_END = 8388610,
  VIDEO_FRAME = 8388611,
  VIDEO_REQUEST_IDR_FRAME = 8388612,
  VIDEO_INVALIDATE_FRAME = 8388613,
  GROUP_TRACKING = 16777216,
  TRACKING_STREAM_BEGIN = 16777217,
  TRACKING_STREAM_END = 16777218,
  TRACKING_FRAME = 16777219,
  GROUP_SCENE = 33554432,
  SCENE_MESH = 33554433,
  SCENE_ARRAY = 33554434,
  GROUP_MISC = 1073741824,
  GROUP_COMMON = 1077870592,
  GROUP_ALL = 1140785152,
  CONSTANT_GROUP_MASK = 2147418112,
}

export enum PayloadType {
  Unknown = 0,
  MessagePack = 1,
  MemoryPack = 2,
  Json = 4,
  All = 7,
}

export enum ServerStatus {
  Unknown = 0,
  Starting = 1,
  Running = 2,
  Stopping = 3,
  Stopped = 4,
}

export enum TrackingType {
  Face = 1,
  Hands = 2,
  Pose = 4,
  All = 7,
}

export enum UIBadgeVariant {
  Filled = 0,
  Outlined = 1,
}

export enum UIButtonVariant {
  Outlined = 0,
  Contained = 1,
  Text = 2,
}

export enum UIColor {
  Default = 0,
  Primary = 1,
  Secondary = 2,
  Error = 3,
  Warning = 4,
  Info = 5,
  Success = 6,
}

export enum UIContentLinkType {
  Unknown = 0,
  Youtube = 1,
}

export enum UIHeaderLevel {
  Default = 0,
  Large = 1,
  Medium = 2,
  Normal = 3,
  Small = 4,
}

export enum UIIconType {
  None = 0,
  Close = 1,
  Download = 2,
  Delete = 3,
  PinDrop = 4,
  Favorite = 5,
  FavoriteBorder = 6,
  AddCircle = 7,
  AddCircleOutline = 8,
  StarOutline = 9,
  Document = 10,
  GenderMale = 11,
  GenderFemale = 12,
  Upload = 13,
  GenderOther = 14,
}

export enum UIInputVariant {
  Outlined = 0,
  Filled = 1,
  Standard = 2,
}

export enum UIListType {
  Default = 0,
  Unordered = 1,
  Ordered = 2,
  Definition = 3,
}

export enum UISectionType {
  Default = 0,
  ColumnLayout = 1,
  RowLayout = 2,
  Card = 3,
  Right = 4,
  Carousel = 5,
}

export enum UITextType {
  Normal = 0,
  Caption = 1,
  Strong = 2,
  CaptionSmall = 3,
}

export enum UIVisibilityType {
  Always = 0,
  AfterEarlierStable = 1,
}

export enum UserType {
  Unknown = 0,
  Machine = 1,
  Human = 2,
}

export enum VideoCodec {
  Unknown = 0,
  H264 = 1,
  Hevc = 2,
  Vp8 = 3,
}

export interface Action {
  Description: string;
  ActionId: string;
}



export function readActionArray(instanceAsArray: any[]): Action {
  const instance: Action = {
    Description: instanceAsArray[0],
    ActionId: instanceAsArray[1]
  };

  return instance;
}

export function createActionArray(instance: Action): any[] {
  const instanceAsArray: any[] = [
    instance.Description,
    instance.ActionId
  ];

  return instanceAsArray;
}

export function readAction(raw: ArrayBuffer): Action {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionArray(instanceAsArray);
}

export function createAction({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: Action;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionActive {
  Description: string;
  IsFinished: boolean;
}



export function readActionActiveArray(instanceAsArray: any[]): ActionActive {
  const instance: ActionActive = {
    Description: instanceAsArray[0],
    IsFinished: instanceAsArray[1]
  };

  return instance;
}

export function createActionActiveArray(instance: ActionActive): any[] {
  const instanceAsArray: any[] = [
    instance.Description,
    instance.IsFinished
  ];

  return instanceAsArray;
}

export function readActionActive(raw: ArrayBuffer): ActionActive {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionActiveArray(instanceAsArray);
}

export function createActionActive({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionActive;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionActiveArray(instance);
  return createMessage(senderId, 1048578, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionAudioStop {
  AudioStreamId: string;
  FadeoutTimeInSec: number;
}



export function readActionAudioStopArray(instanceAsArray: any[]): ActionAudioStop {
  const instance: ActionAudioStop = {
    AudioStreamId: instanceAsArray[0],
    FadeoutTimeInSec: instanceAsArray[1]
  };

  return instance;
}

export function createActionAudioStopArray(instance: ActionAudioStop): any[] {
  const instanceAsArray: any[] = [
    instance.AudioStreamId,
    instance.FadeoutTimeInSec
  ];

  return instanceAsArray;
}

export function readActionAudioStop(raw: ArrayBuffer): ActionAudioStop {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionAudioStopArray(instanceAsArray);
}

export function createActionAudioStop({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionAudioStop;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionAudioStopArray(instance);
  return createMessage(senderId, 1048610, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionCall {
  ActionId: string;
  CallId: string;
  CallArgumentsJson: string;
}



export function readActionCallArray(instanceAsArray: any[]): ActionCall {
  const instance: ActionCall = {
    ActionId: instanceAsArray[0],
    CallId: instanceAsArray[1],
    CallArgumentsJson: instanceAsArray[2]
  };

  return instance;
}

export function createActionCallArray(instance: ActionCall): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId,
    instance.CallId,
    instance.CallArgumentsJson
  ];

  return instanceAsArray;
}

export function readActionCall(raw: ArrayBuffer): ActionCall {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionCallArray(instanceAsArray);
}

export function createActionCall({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionCall;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionCallArray(instance);
  return createMessage(senderId, 1048577, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionCallResult {
  CallId: string;
  ResultJson: string;
  ActionIds: Record<string, Action>;
}



export function readActionCallResultArray(instanceAsArray: any[]): ActionCallResult {
  const instance: ActionCallResult = {
    CallId: instanceAsArray[0],
    ResultJson: instanceAsArray[1],
    ActionIds: Object.fromEntries(Object.entries(instanceAsArray[2]).map(([k, v]) => [k, readActionArray(v as any)]))
  };

  return instance;
}

export function createActionCallResultArray(instance: ActionCallResult): any[] {
  const instanceAsArray: any[] = [
    instance.CallId,
    instance.ResultJson,
    Object.fromEntries(Object.entries(instance.ActionIds).map(([k, v]) => [k, createActionArray(v as any)]))
  ];

  return instanceAsArray;
}

export function readActionCallResult(raw: ArrayBuffer): ActionCallResult {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionCallResultArray(instanceAsArray);
}

export function createActionCallResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionCallResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionCallResultArray(instance);
  return createMessage(senderId, 1048616, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionCallText {
  ActionId: string;
  Text: string;
}



export function readActionCallTextArray(instanceAsArray: any[]): ActionCallText {
  const instance: ActionCallText = {
    ActionId: instanceAsArray[0],
    Text: instanceAsArray[1]
  };

  return instance;
}

export function createActionCallTextArray(instance: ActionCallText): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId,
    instance.Text
  ];

  return instanceAsArray;
}

export function readActionCallText(raw: ArrayBuffer): ActionCallText {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionCallTextArray(instanceAsArray);
}

export function createActionCallText({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionCallText;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionCallTextArray(instance);
  return createMessage(senderId, 1048611, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionCancelGeneration {
}



export function readActionCancelGenerationArray(instanceAsArray: any[]): ActionCancelGeneration {
  const instance: ActionCancelGeneration = {

  };

  return instance;
}

export function createActionCancelGenerationArray(instance: ActionCancelGeneration): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionCancelGeneration(raw: ArrayBuffer): ActionCancelGeneration {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionCancelGenerationArray(instanceAsArray);
}

export function createActionCancelGeneration({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionCancelGeneration;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionCancelGenerationArray(instance);
  return createMessage(senderId, 1048613, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClassificationDetail {
}



export function readActionClassificationDetailArray(instanceAsArray: any[]): ActionClassificationDetail {
  const instance: ActionClassificationDetail = {

  };

  return instance;
}

export function createActionClassificationDetailArray(instance: ActionClassificationDetail): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionClassificationDetail(raw: ArrayBuffer): ActionClassificationDetail {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionClassificationDetailArray(instanceAsArray);
}

export function createActionClassificationDetail({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClassificationDetail;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionClassificationDetailArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClassificationResult {
}



export function readActionClassificationResultArray(instanceAsArray: any[]): ActionClassificationResult {
  const instance: ActionClassificationResult = {

  };

  return instance;
}

export function createActionClassificationResultArray(instance: ActionClassificationResult): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionClassificationResult(raw: ArrayBuffer): ActionClassificationResult {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionClassificationResultArray(instanceAsArray);
}

export function createActionClassificationResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClassificationResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionClassificationResultArray(instance);
  return createMessage(senderId, 1048609, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClearMessageHistory {
}



export function readActionClearMessageHistoryArray(instanceAsArray: any[]): ActionClearMessageHistory {
  const instance: ActionClearMessageHistory = {

  };

  return instance;
}

export function createActionClearMessageHistoryArray(instance: ActionClearMessageHistory): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionClearMessageHistory(raw: ArrayBuffer): ActionClearMessageHistory {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionClearMessageHistoryArray(instanceAsArray);
}

export function createActionClearMessageHistory({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClearMessageHistory;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionClearMessageHistoryArray(instance);
  return createMessage(senderId, 1048605, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClearState {
}



export function readActionClearStateArray(instanceAsArray: any[]): ActionClearState {
  const instance: ActionClearState = {

  };

  return instance;
}

export function createActionClearStateArray(instance: ActionClearState): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionClearState(raw: ArrayBuffer): ActionClearState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionClearStateArray(instanceAsArray);
}

export function createActionClearState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClearState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionClearStateArray(instance);
  return createMessage(senderId, 1048606, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadAck {
  ActionId: string;
  SequenceId: number;
}



export function readActionFileUploadAckArray(instanceAsArray: any[]): ActionFileUploadAck {
  const instance: ActionFileUploadAck = {
    ActionId: instanceAsArray[0],
    SequenceId: instanceAsArray[1]
  };

  return instance;
}

export function createActionFileUploadAckArray(instance: ActionFileUploadAck): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId,
    instance.SequenceId
  ];

  return instanceAsArray;
}

export function readActionFileUploadAck(raw: ArrayBuffer): ActionFileUploadAck {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFileUploadAckArray(instanceAsArray);
}

export function createActionFileUploadAck({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadAck;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFileUploadAckArray(instance);
  return createMessage(senderId, 1048588, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadBegin {
  ActionId: string;
  FileName: string;
  Mime: string;
  ByteCount: number;
  CheckHash: boolean;
  Hash: string;
}



export function readActionFileUploadBeginArray(instanceAsArray: any[]): ActionFileUploadBegin {
  const instance: ActionFileUploadBegin = {
    ActionId: instanceAsArray[0],
    FileName: instanceAsArray[1],
    Mime: instanceAsArray[2],
    ByteCount: instanceAsArray[3],
    CheckHash: instanceAsArray[4],
    Hash: instanceAsArray[5]
  };

  return instance;
}

export function createActionFileUploadBeginArray(instance: ActionFileUploadBegin): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId,
    instance.FileName,
    instance.Mime,
    instance.ByteCount,
    instance.CheckHash,
    instance.Hash
  ];

  return instanceAsArray;
}

export function readActionFileUploadBegin(raw: ArrayBuffer): ActionFileUploadBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFileUploadBeginArray(instanceAsArray);
}

export function createActionFileUploadBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFileUploadBeginArray(instance);
  return createMessage(senderId, 1048586, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadData {
  ActionId: string;
  Data: Uint8Array;
  SequenceId: number;
}



export function readActionFileUploadDataArray(instanceAsArray: any[]): ActionFileUploadData {
  const instance: ActionFileUploadData = {
    ActionId: instanceAsArray[0],
    Data: instanceAsArray[1],
    SequenceId: instanceAsArray[2]
  };

  return instance;
}

export function createActionFileUploadDataArray(instance: ActionFileUploadData): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId,
    instance.Data,
    instance.SequenceId
  ];

  return instanceAsArray;
}

export function readActionFileUploadData(raw: ArrayBuffer): ActionFileUploadData {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFileUploadDataArray(instanceAsArray);
}

export function createActionFileUploadData({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadData;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFileUploadDataArray(instance);
  return createMessage(senderId, 1048587, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadEnd {
  ActionId: string;
}



export function readActionFileUploadEndArray(instanceAsArray: any[]): ActionFileUploadEnd {
  const instance: ActionFileUploadEnd = {
    ActionId: instanceAsArray[0]
  };

  return instance;
}

export function createActionFileUploadEndArray(instance: ActionFileUploadEnd): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId
  ];

  return instanceAsArray;
}

export function readActionFileUploadEnd(raw: ArrayBuffer): ActionFileUploadEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFileUploadEndArray(instanceAsArray);
}

export function createActionFileUploadEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFileUploadEndArray(instance);
  return createMessage(senderId, 1048589, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadResult {
  ActionId: string;
  IsSuccess: boolean;
  ErrorMessage: string;
}



export function readActionFileUploadResultArray(instanceAsArray: any[]): ActionFileUploadResult {
  const instance: ActionFileUploadResult = {
    ActionId: instanceAsArray[0],
    IsSuccess: instanceAsArray[1],
    ErrorMessage: instanceAsArray[2]
  };

  return instance;
}

export function createActionFileUploadResultArray(instance: ActionFileUploadResult): any[] {
  const instanceAsArray: any[] = [
    instance.ActionId,
    instance.IsSuccess,
    instance.ErrorMessage
  ];

  return instanceAsArray;
}

export function readActionFileUploadResult(raw: ArrayBuffer): ActionFileUploadResult {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFileUploadResultArray(instanceAsArray);
}

export function createActionFileUploadResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFileUploadResultArray(instance);
  return createMessage(senderId, 1048590, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFunctionCall {
  FunctionName: string;
  CallId: string;
  CallArgumentsJson: string[];
}



export function readActionFunctionCallArray(instanceAsArray: any[]): ActionFunctionCall {
  const instance: ActionFunctionCall = {
    FunctionName: instanceAsArray[0],
    CallId: instanceAsArray[1],
    CallArgumentsJson: instanceAsArray[2]
  };

  return instance;
}

export function createActionFunctionCallArray(instance: ActionFunctionCall): any[] {
  const instanceAsArray: any[] = [
    instance.FunctionName,
    instance.CallId,
    instance.CallArgumentsJson
  ];

  return instanceAsArray;
}

export function readActionFunctionCall(raw: ArrayBuffer): ActionFunctionCall {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFunctionCallArray(instanceAsArray);
}

export function createActionFunctionCall({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFunctionCall;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFunctionCallArray(instance);
  return createMessage(senderId, 1048601, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFunctionCallResult {
  CallId: string;
  ResultJson: string;
  ResultValueType: ProtocolValueType;
}



export function readActionFunctionCallResultArray(instanceAsArray: any[]): ActionFunctionCallResult {
  const instance: ActionFunctionCallResult = {
    CallId: instanceAsArray[0],
    ResultJson: instanceAsArray[1],
    ResultValueType: readProtocolValueTypeArray(instanceAsArray[2])
  };

  return instance;
}

export function createActionFunctionCallResultArray(instance: ActionFunctionCallResult): any[] {
  const instanceAsArray: any[] = [
    instance.CallId,
    instance.ResultJson,
    createProtocolValueTypeArray(instance.ResultValueType)
  ];

  return instanceAsArray;
}

export function readActionFunctionCallResult(raw: ArrayBuffer): ActionFunctionCallResult {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFunctionCallResultArray(instanceAsArray);
}

export function createActionFunctionCallResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFunctionCallResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFunctionCallResultArray(instance);
  return createMessage(senderId, 1048602, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFunctionRegister {
  FunctionName: string;
  Description: string;
  InlineCall: boolean;
  CallOnlyOnce: boolean;
  ReturnValueType: ProtocolValueType;
  Parameters: FunctionParameter[];
}



export function readActionFunctionRegisterArray(instanceAsArray: any[]): ActionFunctionRegister {
  const instance: ActionFunctionRegister = {
    FunctionName: instanceAsArray[0],
    Description: instanceAsArray[1],
    InlineCall: instanceAsArray[2],
    CallOnlyOnce: instanceAsArray[3],
    ReturnValueType: readProtocolValueTypeArray(instanceAsArray[4]),
    Parameters: instanceAsArray[5].map(readFunctionParameterArray)
  };

  return instance;
}

export function createActionFunctionRegisterArray(instance: ActionFunctionRegister): any[] {
  const instanceAsArray: any[] = [
    instance.FunctionName,
    instance.Description,
    instance.InlineCall,
    instance.CallOnlyOnce,
    createProtocolValueTypeArray(instance.ReturnValueType),
    instance.Parameters.map(createFunctionParameterArray)
  ];

  return instanceAsArray;
}

export function readActionFunctionRegister(raw: ArrayBuffer): ActionFunctionRegister {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionFunctionRegisterArray(instanceAsArray);
}

export function createActionFunctionRegister({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFunctionRegister;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionFunctionRegisterArray(instance);
  return createMessage(senderId, 1048600, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionGenerateAnswer {
}



export function readActionGenerateAnswerArray(instanceAsArray: any[]): ActionGenerateAnswer {
  const instance: ActionGenerateAnswer = {

  };

  return instance;
}

export function createActionGenerateAnswerArray(instance: ActionGenerateAnswer): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionGenerateAnswer(raw: ArrayBuffer): ActionGenerateAnswer {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionGenerateAnswerArray(instanceAsArray);
}

export function createActionGenerateAnswer({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionGenerateAnswer;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionGenerateAnswerArray(instance);
  return createMessage(senderId, 1048603, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionOpenChannel {
  ChannelCode: string;
  Prompt: string;
}



export function readActionOpenChannelArray(instanceAsArray: any[]): ActionOpenChannel {
  const instance: ActionOpenChannel = {
    ChannelCode: instanceAsArray[0],
    Prompt: instanceAsArray[1]
  };

  return instance;
}

export function createActionOpenChannelArray(instance: ActionOpenChannel): any[] {
  const instanceAsArray: any[] = [
    instance.ChannelCode,
    instance.Prompt
  ];

  return instanceAsArray;
}

export function readActionOpenChannel(raw: ArrayBuffer): ActionOpenChannel {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionOpenChannelArray(instanceAsArray);
}

export function createActionOpenChannel({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionOpenChannel;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionOpenChannelArray(instance);
  return createMessage(senderId, 1048591, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionOpenExternalUrl {
  Name: string;
  Url: string;
}



export function readActionOpenExternalUrlArray(instanceAsArray: any[]): ActionOpenExternalUrl {
  const instance: ActionOpenExternalUrl = {
    Name: instanceAsArray[0],
    Url: instanceAsArray[1]
  };

  return instance;
}

export function createActionOpenExternalUrlArray(instance: ActionOpenExternalUrl): any[] {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.Url
  ];

  return instanceAsArray;
}

export function readActionOpenExternalUrl(raw: ArrayBuffer): ActionOpenExternalUrl {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionOpenExternalUrlArray(instanceAsArray);
}

export function createActionOpenExternalUrl({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionOpenExternalUrl;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionOpenExternalUrlArray(instance);
  return createMessage(senderId, 1048592, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionPan {
  Location: Coordinate2D;
  Delta: Coordinate2D;
}



export function readActionPanArray(instanceAsArray: any[]): ActionPan {
  const instance: ActionPan = {
    Location: readCoordinate2DArray(instanceAsArray[0]),
    Delta: readCoordinate2DArray(instanceAsArray[1])
  };

  return instance;
}

export function createActionPanArray(instance: ActionPan): any[] {
  const instanceAsArray: any[] = [
    createCoordinate2DArray(instance.Location),
    createCoordinate2DArray(instance.Delta)
  ];

  return instanceAsArray;
}

export function readActionPan(raw: ArrayBuffer): ActionPan {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionPanArray(instanceAsArray);
}

export function createActionPan({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionPan;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionPanArray(instance);
  return createMessage(senderId, 1048584, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionRegenerateAnswer {
}



export function readActionRegenerateAnswerArray(instanceAsArray: any[]): ActionRegenerateAnswer {
  const instance: ActionRegenerateAnswer = {

  };

  return instance;
}

export function createActionRegenerateAnswerArray(instance: ActionRegenerateAnswer): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionRegenerateAnswer(raw: ArrayBuffer): ActionRegenerateAnswer {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionRegenerateAnswerArray(instanceAsArray);
}

export function createActionRegenerateAnswer({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionRegenerateAnswer;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionRegenerateAnswerArray(instance);
  return createMessage(senderId, 1048604, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionReloadApplication {
  ApplicationId: string;
}



export function readActionReloadApplicationArray(instanceAsArray: any[]): ActionReloadApplication {
  const instance: ActionReloadApplication = {
    ApplicationId: instanceAsArray[0]
  };

  return instance;
}

export function createActionReloadApplicationArray(instance: ActionReloadApplication): any[] {
  const instanceAsArray: any[] = [
    instance.ApplicationId
  ];

  return instanceAsArray;
}

export function readActionReloadApplication(raw: ArrayBuffer): ActionReloadApplication {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionReloadApplicationArray(instanceAsArray);
}

export function createActionReloadApplication({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionReloadApplication;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionReloadApplicationArray(instance);
  return createMessage(senderId, 1048612, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionReloadChannels {
}



export function readActionReloadChannelsArray(instanceAsArray: any[]): ActionReloadChannels {
  const instance: ActionReloadChannels = {

  };

  return instance;
}

export function createActionReloadChannelsArray(instance: ActionReloadChannels): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionReloadChannels(raw: ArrayBuffer): ActionReloadChannels {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionReloadChannelsArray(instanceAsArray);
}

export function createActionReloadChannels({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionReloadChannels;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionReloadChannelsArray(instance);
  return createMessage(senderId, 1048607, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionReloadProfile {
  ProfileId: string;
  UserId: string;
}



export function readActionReloadProfileArray(instanceAsArray: any[]): ActionReloadProfile {
  const instance: ActionReloadProfile = {
    ProfileId: instanceAsArray[0],
    UserId: instanceAsArray[1]
  };

  return instance;
}

export function createActionReloadProfileArray(instance: ActionReloadProfile): any[] {
  const instanceAsArray: any[] = [
    instance.ProfileId,
    instance.UserId
  ];

  return instanceAsArray;
}

export function readActionReloadProfile(raw: ArrayBuffer): ActionReloadProfile {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionReloadProfileArray(instanceAsArray);
}

export function createActionReloadProfile({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionReloadProfile;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionReloadProfileArray(instance);
  return createMessage(senderId, 1048608, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionSetState {
  Key: string;
  ValueJson: string;
  ValueType: ProtocolValueType;
}



export function readActionSetStateArray(instanceAsArray: any[]): ActionSetState {
  const instance: ActionSetState = {
    Key: instanceAsArray[0],
    ValueJson: instanceAsArray[1],
    ValueType: readProtocolValueTypeArray(instanceAsArray[2])
  };

  return instance;
}

export function createActionSetStateArray(instance: ActionSetState): any[] {
  const instanceAsArray: any[] = [
    instance.Key,
    instance.ValueJson,
    createProtocolValueTypeArray(instance.ValueType)
  ];

  return instanceAsArray;
}

export function readActionSetState(raw: ArrayBuffer): ActionSetState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionSetStateArray(instanceAsArray);
}

export function createActionSetState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionSetState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionSetStateArray(instance);
  return createMessage(senderId, 1048582, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionSpeechRecognized {
  WasSuccessful: boolean;
  Text: string;
}



export function readActionSpeechRecognizedArray(instanceAsArray: any[]): ActionSpeechRecognized {
  const instance: ActionSpeechRecognized = {
    WasSuccessful: instanceAsArray[0],
    Text: instanceAsArray[1]
  };

  return instance;
}

export function createActionSpeechRecognizedArray(instance: ActionSpeechRecognized): any[] {
  const instanceAsArray: any[] = [
    instance.WasSuccessful,
    instance.Text
  ];

  return instanceAsArray;
}

export function readActionSpeechRecognized(raw: ArrayBuffer): ActionSpeechRecognized {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionSpeechRecognizedArray(instanceAsArray);
}

export function createActionSpeechRecognized({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionSpeechRecognized;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionSpeechRecognizedArray(instance);
  return createMessage(senderId, 1048615, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTap {
  Location: Coordinate2D;
}



export function readActionTapArray(instanceAsArray: any[]): ActionTap {
  const instance: ActionTap = {
    Location: readCoordinate2DArray(instanceAsArray[0])
  };

  return instance;
}

export function createActionTapArray(instance: ActionTap): any[] {
  const instanceAsArray: any[] = [
    createCoordinate2DArray(instance.Location)
  ];

  return instanceAsArray;
}

export function readActionTap(raw: ArrayBuffer): ActionTap {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionTapArray(instanceAsArray);
}

export function createActionTap({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTap;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionTapArray(instance);
  return createMessage(senderId, 1048583, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTextOutput {
  UserId: string;
  Text: string;
  GenerateChatMessage: boolean;
  CreatedAt: string;
  PreciseCreatedAt: bigint;
}



export function readActionTextOutputArray(instanceAsArray: any[]): ActionTextOutput {
  const instance: ActionTextOutput = {
    UserId: instanceAsArray[0],
    Text: instanceAsArray[1],
    GenerateChatMessage: instanceAsArray[2],
    CreatedAt: instanceAsArray[3],
    PreciseCreatedAt: instanceAsArray[4]
  };

  return instance;
}

export function createActionTextOutputArray(instance: ActionTextOutput): any[] {
  const instanceAsArray: any[] = [
    instance.UserId,
    instance.Text,
    instance.GenerateChatMessage,
    instance.CreatedAt,
    instance.PreciseCreatedAt
  ];

  return instanceAsArray;
}

export function readActionTextOutput(raw: ArrayBuffer): ActionTextOutput {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionTextOutputArray(instanceAsArray);
}

export function createActionTextOutput({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTextOutput;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionTextOutputArray(instance);
  return createMessage(senderId, 1048579, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTextOutputDelta {
  Delta: string;
}



export function readActionTextOutputDeltaArray(instanceAsArray: any[]): ActionTextOutputDelta {
  const instance: ActionTextOutputDelta = {
    Delta: instanceAsArray[0]
  };

  return instance;
}

export function createActionTextOutputDeltaArray(instance: ActionTextOutputDelta): any[] {
  const instanceAsArray: any[] = [
    instance.Delta
  ];

  return instanceAsArray;
}

export function readActionTextOutputDelta(raw: ArrayBuffer): ActionTextOutputDelta {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionTextOutputDeltaArray(instanceAsArray);
}

export function createActionTextOutputDelta({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTextOutputDelta;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionTextOutputDeltaArray(instance);
  return createMessage(senderId, 1048580, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTextOutputDeltaFull {
  Full: string;
}



export function readActionTextOutputDeltaFullArray(instanceAsArray: any[]): ActionTextOutputDeltaFull {
  const instance: ActionTextOutputDeltaFull = {
    Full: instanceAsArray[0]
  };

  return instance;
}

export function createActionTextOutputDeltaFullArray(instance: ActionTextOutputDeltaFull): any[] {
  const instanceAsArray: any[] = [
    instance.Full
  ];

  return instanceAsArray;
}

export function readActionTextOutputDeltaFull(raw: ArrayBuffer): ActionTextOutputDeltaFull {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionTextOutputDeltaFullArray(instanceAsArray);
}

export function createActionTextOutputDeltaFull({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTextOutputDeltaFull;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionTextOutputDeltaFullArray(instance);
  return createMessage(senderId, 1048581, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIBlockingBegin {
}



export function readActionUIBlockingBeginArray(instanceAsArray: any[]): ActionUIBlockingBegin {
  const instance: ActionUIBlockingBegin = {

  };

  return instance;
}

export function createActionUIBlockingBeginArray(instance: ActionUIBlockingBegin): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionUIBlockingBegin(raw: ArrayBuffer): ActionUIBlockingBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUIBlockingBeginArray(instanceAsArray);
}

export function createActionUIBlockingBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIBlockingBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUIBlockingBeginArray(instance);
  return createMessage(senderId, 1048595, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIBlockingEnd {
}



export function readActionUIBlockingEndArray(instanceAsArray: any[]): ActionUIBlockingEnd {
  const instance: ActionUIBlockingEnd = {

  };

  return instance;
}

export function createActionUIBlockingEndArray(instance: ActionUIBlockingEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionUIBlockingEnd(raw: ArrayBuffer): ActionUIBlockingEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUIBlockingEndArray(instanceAsArray);
}

export function createActionUIBlockingEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIBlockingEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUIBlockingEndArray(instance);
  return createMessage(senderId, 1048596, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUICloseView {
}



export function readActionUICloseViewArray(instanceAsArray: any[]): ActionUICloseView {
  const instance: ActionUICloseView = {

  };

  return instance;
}

export function createActionUICloseViewArray(instance: ActionUICloseView): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionUICloseView(raw: ArrayBuffer): ActionUICloseView {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUICloseViewArray(instanceAsArray);
}

export function createActionUICloseView({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUICloseView;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUICloseViewArray(instance);
  return createMessage(senderId, 1048594, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIDeleteContainer {
  ContainerId: string;
}



export function readActionUIDeleteContainerArray(instanceAsArray: any[]): ActionUIDeleteContainer {
  const instance: ActionUIDeleteContainer = {
    ContainerId: instanceAsArray[0]
  };

  return instance;
}

export function createActionUIDeleteContainerArray(instance: ActionUIDeleteContainer): any[] {
  const instanceAsArray: any[] = [
    instance.ContainerId
  ];

  return instanceAsArray;
}

export function readActionUIDeleteContainer(raw: ArrayBuffer): ActionUIDeleteContainer {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUIDeleteContainerArray(instanceAsArray);
}

export function createActionUIDeleteContainer({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIDeleteContainer;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUIDeleteContainerArray(instance);
  return createMessage(senderId, 1048598, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIOpenView {
}



export function readActionUIOpenViewArray(instanceAsArray: any[]): ActionUIOpenView {
  const instance: ActionUIOpenView = {

  };

  return instance;
}

export function createActionUIOpenViewArray(instance: ActionUIOpenView): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readActionUIOpenView(raw: ArrayBuffer): ActionUIOpenView {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUIOpenViewArray(instanceAsArray);
}

export function createActionUIOpenView({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIOpenView;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUIOpenViewArray(instance);
  return createMessage(senderId, 1048593, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUISetContainerStable {
  ContainerId: string;
}



export function readActionUISetContainerStableArray(instanceAsArray: any[]): ActionUISetContainerStable {
  const instance: ActionUISetContainerStable = {
    ContainerId: instanceAsArray[0]
  };

  return instance;
}

export function createActionUISetContainerStableArray(instance: ActionUISetContainerStable): any[] {
  const instanceAsArray: any[] = [
    instance.ContainerId
  ];

  return instanceAsArray;
}

export function readActionUISetContainerStable(raw: ArrayBuffer): ActionUISetContainerStable {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUISetContainerStableArray(instanceAsArray);
}

export function createActionUISetContainerStable({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUISetContainerStable;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUISetContainerStableArray(instance);
  return createMessage(senderId, 1048614, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIUpdateTextDelta {
  ContainerId: string;
  ElementId: number;
  Delta: string;
}



export function readActionUIUpdateTextDeltaArray(instanceAsArray: any[]): ActionUIUpdateTextDelta {
  const instance: ActionUIUpdateTextDelta = {
    ContainerId: instanceAsArray[0],
    ElementId: instanceAsArray[1],
    Delta: instanceAsArray[2]
  };

  return instance;
}

export function createActionUIUpdateTextDeltaArray(instance: ActionUIUpdateTextDelta): any[] {
  const instanceAsArray: any[] = [
    instance.ContainerId,
    instance.ElementId,
    instance.Delta
  ];

  return instanceAsArray;
}

export function readActionUIUpdateTextDelta(raw: ArrayBuffer): ActionUIUpdateTextDelta {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUIUpdateTextDeltaArray(instanceAsArray);
}

export function createActionUIUpdateTextDelta({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIUpdateTextDelta;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUIUpdateTextDeltaArray(instance);
  return createMessage(senderId, 1048597, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUpdateGfxShader {
  Name: string;
  Fps: number;
  Content: string;
  ContentHash: string;
}



export function readActionUpdateGfxShaderArray(instanceAsArray: any[]): ActionUpdateGfxShader {
  const instance: ActionUpdateGfxShader = {
    Name: instanceAsArray[0],
    Fps: instanceAsArray[1],
    Content: instanceAsArray[2],
    ContentHash: instanceAsArray[3]
  };

  return instance;
}

export function createActionUpdateGfxShaderArray(instance: ActionUpdateGfxShader): any[] {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.Fps,
    instance.Content,
    instance.ContentHash
  ];

  return instanceAsArray;
}

export function readActionUpdateGfxShader(raw: ArrayBuffer): ActionUpdateGfxShader {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionUpdateGfxShaderArray(instanceAsArray);
}

export function createActionUpdateGfxShader({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUpdateGfxShader;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionUpdateGfxShaderArray(instance);
  return createMessage(senderId, 1048599, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionZoom {
  StartScale: number;
  CurrentScale: number;
}



export function readActionZoomArray(instanceAsArray: any[]): ActionZoom {
  const instance: ActionZoom = {
    StartScale: instanceAsArray[0],
    CurrentScale: instanceAsArray[1]
  };

  return instance;
}

export function createActionZoomArray(instance: ActionZoom): any[] {
  const instanceAsArray: any[] = [
    instance.StartScale,
    instance.CurrentScale
  ];

  return instanceAsArray;
}

export function readActionZoom(raw: ArrayBuffer): ActionZoom {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readActionZoomArray(instanceAsArray);
}

export function createActionZoom({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionZoom;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createActionZoomArray(instance);
  return createMessage(senderId, 1048585, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsEvents {
  Events: AnalyticsEventsItem[];
}



export function readAnalyticsEventsArray(instanceAsArray: any[]): AnalyticsEvents {
  const instance: AnalyticsEvents = {
    Events: instanceAsArray[0].map(readAnalyticsEventsItemArray)
  };

  return instance;
}

export function createAnalyticsEventsArray(instance: AnalyticsEvents): any[] {
  const instanceAsArray: any[] = [
    instance.Events.map(createAnalyticsEventsItemArray)
  ];

  return instanceAsArray;
}

export function readAnalyticsEvents(raw: ArrayBuffer): AnalyticsEvents {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsEventsArray(instanceAsArray);
}

export function createAnalyticsEvents({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsEvents;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsEventsArray(instance);
  return createMessage(senderId, 524290, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsEventsItem {
  Time: string;
  EventName: string;
  Message: string;
  Parameters: string;
}



export function readAnalyticsEventsItemArray(instanceAsArray: any[]): AnalyticsEventsItem {
  const instance: AnalyticsEventsItem = {
    Time: instanceAsArray[0],
    EventName: instanceAsArray[1],
    Message: instanceAsArray[2],
    Parameters: instanceAsArray[3]
  };

  return instance;
}

export function createAnalyticsEventsItemArray(instance: AnalyticsEventsItem): any[] {
  const instanceAsArray: any[] = [
    instance.Time,
    instance.EventName,
    instance.Message,
    instance.Parameters
  ];

  return instanceAsArray;
}

export function readAnalyticsEventsItem(raw: ArrayBuffer): AnalyticsEventsItem {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsEventsItemArray(instanceAsArray);
}

export function createAnalyticsEventsItem({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsEventsItem;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsEventsItemArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsLogs {
  Logs: AnalyticsLogsItem[];
}



export function readAnalyticsLogsArray(instanceAsArray: any[]): AnalyticsLogs {
  const instance: AnalyticsLogs = {
    Logs: instanceAsArray[0].map(readAnalyticsLogsItemArray)
  };

  return instance;
}

export function createAnalyticsLogsArray(instance: AnalyticsLogs): any[] {
  const instanceAsArray: any[] = [
    instance.Logs.map(createAnalyticsLogsItemArray)
  ];

  return instanceAsArray;
}

export function readAnalyticsLogs(raw: ArrayBuffer): AnalyticsLogs {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsLogsArray(instanceAsArray);
}

export function createAnalyticsLogs({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsLogs;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsLogsArray(instance);
  return createMessage(senderId, 524289, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 4, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsLogsItem {
  Time: string;
  Type: number;
  Message: string;
  Parameters: string;
}



export function readAnalyticsLogsItemArray(instanceAsArray: any[]): AnalyticsLogsItem {
  const instance: AnalyticsLogsItem = {
    Time: instanceAsArray[0],
    Type: instanceAsArray[1],
    Message: instanceAsArray[2],
    Parameters: instanceAsArray[3]
  };

  return instance;
}

export function createAnalyticsLogsItemArray(instance: AnalyticsLogsItem): any[] {
  const instanceAsArray: any[] = [
    instance.Time,
    instance.Type,
    instance.Message,
    instance.Parameters
  ];

  return instanceAsArray;
}

export function readAnalyticsLogsItem(raw: ArrayBuffer): AnalyticsLogsItem {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsLogsItemArray(instanceAsArray);
}

export function createAnalyticsLogsItem({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsLogsItem;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsLogsItemArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsProcessingUpdate {
  ProcessingId: string;
  TotalRuns: number;
  TotalItems: number;
  TotalPages: number;
  TotalRetries: number;
  TotalFailures: number;
  StartedAt: string;
  ElapsedSeconds: number;
  Usages: Record<string, number>;
  RunsRemaining: number;
  ItemsRemaining: number;
  EstimatedTimeLeftSecondsRuns: number;
  EstimatedTimeLeftSecondsItems: number;
}



export function readAnalyticsProcessingUpdateArray(instanceAsArray: any[]): AnalyticsProcessingUpdate {
  const instance: AnalyticsProcessingUpdate = {
    ProcessingId: instanceAsArray[0],
    TotalRuns: instanceAsArray[1],
    TotalItems: instanceAsArray[2],
    TotalPages: instanceAsArray[3],
    TotalRetries: instanceAsArray[4],
    TotalFailures: instanceAsArray[5],
    StartedAt: instanceAsArray[6],
    ElapsedSeconds: instanceAsArray[7],
    Usages: instanceAsArray[8],
    RunsRemaining: instanceAsArray[9],
    ItemsRemaining: instanceAsArray[10],
    EstimatedTimeLeftSecondsRuns: instanceAsArray[11],
    EstimatedTimeLeftSecondsItems: instanceAsArray[12]
  };

  return instance;
}

export function createAnalyticsProcessingUpdateArray(instance: AnalyticsProcessingUpdate): any[] {
  const instanceAsArray: any[] = [
    instance.ProcessingId,
    instance.TotalRuns,
    instance.TotalItems,
    instance.TotalPages,
    instance.TotalRetries,
    instance.TotalFailures,
    instance.StartedAt,
    instance.ElapsedSeconds,
    instance.Usages,
    instance.RunsRemaining,
    instance.ItemsRemaining,
    instance.EstimatedTimeLeftSecondsRuns,
    instance.EstimatedTimeLeftSecondsItems
  ];

  return instanceAsArray;
}

export function readAnalyticsProcessingUpdate(raw: ArrayBuffer): AnalyticsProcessingUpdate {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsProcessingUpdateArray(instanceAsArray);
}

export function createAnalyticsProcessingUpdate({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsProcessingUpdate;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsProcessingUpdateArray(instance);
  return createMessage(senderId, 524294, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsSpecialLog {
  Title: string;
  Message: string;
}



export function readAnalyticsSpecialLogArray(instanceAsArray: any[]): AnalyticsSpecialLog {
  const instance: AnalyticsSpecialLog = {
    Title: instanceAsArray[0],
    Message: instanceAsArray[1]
  };

  return instance;
}

export function createAnalyticsSpecialLogArray(instance: AnalyticsSpecialLog): any[] {
  const instanceAsArray: any[] = [
    instance.Title,
    instance.Message
  ];

  return instanceAsArray;
}

export function readAnalyticsSpecialLog(raw: ArrayBuffer): AnalyticsSpecialLog {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsSpecialLogArray(instanceAsArray);
}

export function createAnalyticsSpecialLog({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsSpecialLog;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsSpecialLogArray(instance);
  return createMessage(senderId, 524293, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsUsage {
  UsageName: string;
  Usage: number;
}



export function readAnalyticsUsageArray(instanceAsArray: any[]): AnalyticsUsage {
  const instance: AnalyticsUsage = {
    UsageName: instanceAsArray[0],
    Usage: instanceAsArray[1]
  };

  return instance;
}

export function createAnalyticsUsageArray(instance: AnalyticsUsage): any[] {
  const instanceAsArray: any[] = [
    instance.UsageName,
    instance.Usage
  ];

  return instanceAsArray;
}

export function readAnalyticsUsage(raw: ArrayBuffer): AnalyticsUsage {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsUsageArray(instanceAsArray);
}

export function createAnalyticsUsage({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsUsage;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsUsageArray(instance);
  return createMessage(senderId, 524292, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsUsages {
  Usages: AnalyticsUsagesItem[];
}



export function readAnalyticsUsagesArray(instanceAsArray: any[]): AnalyticsUsages {
  const instance: AnalyticsUsages = {
    Usages: instanceAsArray[0].map(readAnalyticsUsagesItemArray)
  };

  return instance;
}

export function createAnalyticsUsagesArray(instance: AnalyticsUsages): any[] {
  const instanceAsArray: any[] = [
    instance.Usages.map(createAnalyticsUsagesItemArray)
  ];

  return instanceAsArray;
}

export function readAnalyticsUsages(raw: ArrayBuffer): AnalyticsUsages {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsUsagesArray(instanceAsArray);
}

export function createAnalyticsUsages({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsUsages;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsUsagesArray(instance);
  return createMessage(senderId, 524291, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsUsagesItem {
  Time: string;
  EventName: string;
  Usage: number;
  Parameters: string;
}



export function readAnalyticsUsagesItemArray(instanceAsArray: any[]): AnalyticsUsagesItem {
  const instance: AnalyticsUsagesItem = {
    Time: instanceAsArray[0],
    EventName: instanceAsArray[1],
    Usage: instanceAsArray[2],
    Parameters: instanceAsArray[3]
  };

  return instance;
}

export function createAnalyticsUsagesItemArray(instance: AnalyticsUsagesItem): any[] {
  const instanceAsArray: any[] = [
    instance.Time,
    instance.EventName,
    instance.Usage,
    instance.Parameters
  ];

  return instanceAsArray;
}

export function readAnalyticsUsagesItem(raw: ArrayBuffer): AnalyticsUsagesItem {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAnalyticsUsagesItemArray(instanceAsArray);
}

export function createAnalyticsUsagesItem({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsUsagesItem;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAnalyticsUsagesItemArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioFrame {
  Data: Uint8Array;
  IsKey: boolean;
  IsLast: boolean;
  TimestampInUs: bigint;
  DurationInUs: number;
  IsFirst: boolean;
  TotalDurationInUs: number;
}



export function readAudioFrameArray(instanceAsArray: any[]): AudioFrame {
  const instance: AudioFrame = {
    Data: instanceAsArray[0],
    IsKey: instanceAsArray[1],
    IsLast: instanceAsArray[2],
    TimestampInUs: instanceAsArray[3],
    DurationInUs: instanceAsArray[4],
    IsFirst: instanceAsArray[5],
    TotalDurationInUs: instanceAsArray[6]
  };

  return instance;
}

export function createAudioFrameArray(instance: AudioFrame): any[] {
  const instanceAsArray: any[] = [
    instance.Data,
    instance.IsKey,
    instance.IsLast,
    instance.TimestampInUs,
    instance.DurationInUs,
    instance.IsFirst,
    instance.TotalDurationInUs
  ];

  return instanceAsArray;
}

export function readAudioFrame(raw: ArrayBuffer): AudioFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAudioFrameArray(instanceAsArray);
}

export function createAudioFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAudioFrameArray(instance);
  return createMessage(senderId, 4194307, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioFrameVolume {
  Volume: number;
  Count: number;
}



export function readAudioFrameVolumeArray(instanceAsArray: any[]): AudioFrameVolume {
  const instance: AudioFrameVolume = {
    Volume: instanceAsArray[0],
    Count: instanceAsArray[1]
  };

  return instance;
}

export function createAudioFrameVolumeArray(instance: AudioFrameVolume): any[] {
  const instanceAsArray: any[] = [
    instance.Volume,
    instance.Count
  ];

  return instanceAsArray;
}

export function readAudioFrameVolume(raw: ArrayBuffer): AudioFrameVolume {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAudioFrameVolumeArray(instanceAsArray);
}

export function createAudioFrameVolume({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioFrameVolume;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAudioFrameVolumeArray(instance);
  return createMessage(senderId, 4194308, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioStreamBegin {
  Description: string;
  Codec: AudioCodec;
  SampleRate: number;
  Channels: number;
  BitDepth: number;
}



export function readAudioStreamBeginArray(instanceAsArray: any[]): AudioStreamBegin {
  const instance: AudioStreamBegin = {
    Description: instanceAsArray[0],
    Codec: instanceAsArray[1],
    SampleRate: instanceAsArray[2],
    Channels: instanceAsArray[3],
    BitDepth: instanceAsArray[4]
  };

  return instance;
}

export function createAudioStreamBeginArray(instance: AudioStreamBegin): any[] {
  const instanceAsArray: any[] = [
    instance.Description,
    instance.Codec,
    instance.SampleRate,
    instance.Channels,
    instance.BitDepth
  ];

  return instanceAsArray;
}

export function readAudioStreamBegin(raw: ArrayBuffer): AudioStreamBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAudioStreamBeginArray(instanceAsArray);
}

export function createAudioStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAudioStreamBeginArray(instance);
  return createMessage(senderId, 4194305, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioStreamEnd {
}



export function readAudioStreamEndArray(instanceAsArray: any[]): AudioStreamEnd {
  const instance: AudioStreamEnd = {

  };

  return instance;
}

export function createAudioStreamEndArray(instance: AudioStreamEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readAudioStreamEnd(raw: ArrayBuffer): AudioStreamEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAudioStreamEndArray(instanceAsArray);
}

export function createAudioStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAudioStreamEndArray(instance);
  return createMessage(senderId, 4194306, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: AudioStreamBegin;
}



export function readAudioStreamStateArray(instanceAsArray: any[]): AudioStreamState {
  const instance: AudioStreamState = {
    StreamId: instanceAsArray[0],
    ClientSessionId: instanceAsArray[1],
    TrackId: instanceAsArray[2],
    Info: readAudioStreamBeginArray(instanceAsArray[3])
  };

  return instance;
}

export function createAudioStreamStateArray(instance: AudioStreamState): any[] {
  const instanceAsArray: any[] = [
    instance.StreamId,
    instance.ClientSessionId,
    instance.TrackId,
    createAudioStreamBeginArray(instance.Info)
  ];

  return instanceAsArray;
}

export function readAudioStreamState(raw: ArrayBuffer): AudioStreamState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAudioStreamStateArray(instanceAsArray);
}

export function createAudioStreamState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioStreamState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAudioStreamStateArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AuthResponse {
  ClientContext: Context;
  ServerContext: Context;
  CertHash: string;
  Entrypoints: Entrypoint[];
  FeatureFlags: Record<string, boolean>;
  SpaceId: string;
  ChannelId: string;
  PrimaryUserId: string;
  ChannelTemplateId: string;
}



export function readAuthResponseArray(instanceAsArray: any[]): AuthResponse {
  const instance: AuthResponse = {
    ClientContext: readContextArray(instanceAsArray[0]),
    ServerContext: readContextArray(instanceAsArray[1]),
    CertHash: instanceAsArray[2],
    Entrypoints: instanceAsArray[3].map(readEntrypointArray),
    FeatureFlags: instanceAsArray[4],
    SpaceId: instanceAsArray[5],
    ChannelId: instanceAsArray[6],
    PrimaryUserId: instanceAsArray[7],
    ChannelTemplateId: instanceAsArray[8]
  };

  return instance;
}

export function createAuthResponseArray(instance: AuthResponse): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ClientContext),
    createContextArray(instance.ServerContext),
    instance.CertHash,
    instance.Entrypoints.map(createEntrypointArray),
    instance.FeatureFlags,
    instance.SpaceId,
    instance.ChannelId,
    instance.PrimaryUserId,
    instance.ChannelTemplateId
  ];

  return instanceAsArray;
}

export function readAuthResponse(raw: ArrayBuffer): AuthResponse {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAuthResponseArray(instanceAsArray);
}

export function createAuthResponse({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AuthResponse;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAuthResponseArray(instance);
  return createMessage(senderId, 65537, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 5, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AuthTicket {
  Host: string;
  HttpsPort: number;
  TcpPort: number;
  Secret: string;
  OpcodeGroupsFromServer: Opcode;
  OpcodeGroupsToServer: Opcode;
  ClientContext: Context;
}



export function readAuthTicketArray(instanceAsArray: any[]): AuthTicket {
  const instance: AuthTicket = {
    Host: instanceAsArray[0],
    HttpsPort: instanceAsArray[1],
    TcpPort: instanceAsArray[2],
    Secret: instanceAsArray[3],
    OpcodeGroupsFromServer: instanceAsArray[4],
    OpcodeGroupsToServer: instanceAsArray[5],
    ClientContext: readContextArray(instanceAsArray[6])
  };

  return instance;
}

export function createAuthTicketArray(instance: AuthTicket): any[] {
  const instanceAsArray: any[] = [
    instance.Host,
    instance.HttpsPort,
    instance.TcpPort,
    instance.Secret,
    instance.OpcodeGroupsFromServer,
    instance.OpcodeGroupsToServer,
    createContextArray(instance.ClientContext)
  ];

  return instanceAsArray;
}

export function readAuthTicket(raw: ArrayBuffer): AuthTicket {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readAuthTicketArray(instanceAsArray);
}

export function createAuthTicket({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AuthTicket;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createAuthTicketArray(instance);
  return createMessage(senderId, 65538, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ClientReady {
}



export function readClientReadyArray(instanceAsArray: any[]): ClientReady {
  const instance: ClientReady = {

  };

  return instance;
}

export function createClientReadyArray(instance: ClientReady): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readClientReady(raw: ArrayBuffer): ClientReady {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readClientReadyArray(instanceAsArray);
}

export function createClientReady({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ClientReady;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createClientReadyArray(instance);
  return createMessage(senderId, 65549, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ConnectToken {
  ServerSessionId: string;
  ContextType: ContextType;
  UserType: UserType;
  PayloadType: PayloadType;
  IsInternal: boolean;
  Description: string;
  UserId: string;
  DeviceId: string;
  ProductId: string;
  VersionId: string;
  InstallId: string;
  Locale: string;
  OpcodeGroupsFromServer: Opcode;
  OpcodeGroupsToServer: Opcode;
  ProtocolVersion: number;
  HasInput: boolean;
  ChannelLocale: string;
  EmbeddedSpaceId: string;
  AuthSessionId: string;
  ReceiveAllMessages: boolean;
}



export function readConnectTokenArray(instanceAsArray: any[]): ConnectToken {
  const instance: ConnectToken = {
    ServerSessionId: instanceAsArray[0],
    ContextType: instanceAsArray[1],
    UserType: instanceAsArray[2],
    PayloadType: instanceAsArray[3],
    IsInternal: instanceAsArray[4],
    Description: instanceAsArray[5],
    UserId: instanceAsArray[6],
    DeviceId: instanceAsArray[7],
    ProductId: instanceAsArray[8],
    VersionId: instanceAsArray[9],
    InstallId: instanceAsArray[10],
    Locale: instanceAsArray[11],
    OpcodeGroupsFromServer: instanceAsArray[12],
    OpcodeGroupsToServer: instanceAsArray[13],
    ProtocolVersion: instanceAsArray[14],
    HasInput: instanceAsArray[15],
    ChannelLocale: instanceAsArray[16],
    EmbeddedSpaceId: instanceAsArray[17],
    AuthSessionId: instanceAsArray[18],
    ReceiveAllMessages: instanceAsArray[19]
  };

  return instance;
}

export function createConnectTokenArray(instance: ConnectToken): any[] {
  const instanceAsArray: any[] = [
    instance.ServerSessionId,
    instance.ContextType,
    instance.UserType,
    instance.PayloadType,
    instance.IsInternal,
    instance.Description,
    instance.UserId,
    instance.DeviceId,
    instance.ProductId,
    instance.VersionId,
    instance.InstallId,
    instance.Locale,
    instance.OpcodeGroupsFromServer,
    instance.OpcodeGroupsToServer,
    instance.ProtocolVersion,
    instance.HasInput,
    instance.ChannelLocale,
    instance.EmbeddedSpaceId,
    instance.AuthSessionId,
    instance.ReceiveAllMessages
  ];

  return instanceAsArray;
}

export function readConnectToken(raw: ArrayBuffer): ConnectToken {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readConnectTokenArray(instanceAsArray);
}

export function createConnectToken({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ConnectToken;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createConnectTokenArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface Context {
  ContextType: ContextType;
  UserType: UserType;
  PayloadType: PayloadType;
  Description: string;
  UserId: string;
  DeviceId: string;
  ProductId: string;
  VersionId: string;
  InstallId: string;
  Locale: string;
  SessionId: number;
  IsInternal: boolean;
  IsReady: boolean;
  HasInput: boolean;
  ChannelLocale: string;
  EmbeddedSpaceId: string;
  AuthSessionId: string;
  ReceiveAllMessages: boolean;
  PreciseJoinedAt: bigint;
}



export function readContextArray(instanceAsArray: any[]): Context {
  const instance: Context = {
    ContextType: instanceAsArray[0],
    UserType: instanceAsArray[1],
    PayloadType: instanceAsArray[2],
    Description: instanceAsArray[3],
    UserId: instanceAsArray[4],
    DeviceId: instanceAsArray[5],
    ProductId: instanceAsArray[6],
    VersionId: instanceAsArray[7],
    InstallId: instanceAsArray[8],
    Locale: instanceAsArray[9],
    SessionId: instanceAsArray[10],
    IsInternal: instanceAsArray[11],
    IsReady: instanceAsArray[12],
    HasInput: instanceAsArray[13],
    ChannelLocale: instanceAsArray[14],
    EmbeddedSpaceId: instanceAsArray[15],
    AuthSessionId: instanceAsArray[16],
    ReceiveAllMessages: instanceAsArray[17],
    PreciseJoinedAt: instanceAsArray[18]
  };

  return instance;
}

export function createContextArray(instance: Context): any[] {
  const instanceAsArray: any[] = [
    instance.ContextType,
    instance.UserType,
    instance.PayloadType,
    instance.Description,
    instance.UserId,
    instance.DeviceId,
    instance.ProductId,
    instance.VersionId,
    instance.InstallId,
    instance.Locale,
    instance.SessionId,
    instance.IsInternal,
    instance.IsReady,
    instance.HasInput,
    instance.ChannelLocale,
    instance.EmbeddedSpaceId,
    instance.AuthSessionId,
    instance.ReceiveAllMessages,
    instance.PreciseJoinedAt
  ];

  return instanceAsArray;
}

export function readContext(raw: ArrayBuffer): Context {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readContextArray(instanceAsArray);
}

export function createContext({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: Context;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createContextArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface Coordinate2D {
  X: number;
  Y: number;
}



export function readCoordinate2DArray(instanceAsArray: any[]): Coordinate2D {
  const instance: Coordinate2D = {
    X: instanceAsArray[0],
    Y: instanceAsArray[1]
  };

  return instance;
}

export function createCoordinate2DArray(instance: Coordinate2D): any[] {
  const instanceAsArray: any[] = [
    instance.X,
    instance.Y
  ];

  return instanceAsArray;
}

export function readCoordinate2D(raw: ArrayBuffer): Coordinate2D {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readCoordinate2DArray(instanceAsArray);
}

export function createCoordinate2D({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: Coordinate2D;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createCoordinate2DArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface Entrypoint {
  Type: EntrypointType;
  Uri: string;
  OpcodeGroupsFromServer: Opcode;
  OpcodeGroupsToServer: Opcode;
  Priority: number;
  Description: string;
  AuthTicket: Uint8Array;
}



export function readEntrypointArray(instanceAsArray: any[]): Entrypoint {
  const instance: Entrypoint = {
    Type: instanceAsArray[0],
    Uri: instanceAsArray[1],
    OpcodeGroupsFromServer: instanceAsArray[2],
    OpcodeGroupsToServer: instanceAsArray[3],
    Priority: instanceAsArray[4],
    Description: instanceAsArray[5],
    AuthTicket: instanceAsArray[6]
  };

  return instance;
}

export function createEntrypointArray(instance: Entrypoint): any[] {
  const instanceAsArray: any[] = [
    instance.Type,
    instance.Uri,
    instance.OpcodeGroupsFromServer,
    instance.OpcodeGroupsToServer,
    instance.Priority,
    instance.Description,
    instance.AuthTicket
  ];

  return instanceAsArray;
}

export function readEntrypoint(raw: ArrayBuffer): Entrypoint {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readEntrypointArray(instanceAsArray);
}

export function createEntrypoint({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: Entrypoint;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createEntrypointArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface EventsOnChannelComplete {
}



export function readEventsOnChannelCompleteArray(instanceAsArray: any[]): EventsOnChannelComplete {
  const instance: EventsOnChannelComplete = {

  };

  return instance;
}

export function createEventsOnChannelCompleteArray(instance: EventsOnChannelComplete): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readEventsOnChannelComplete(raw: ArrayBuffer): EventsOnChannelComplete {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readEventsOnChannelCompleteArray(instanceAsArray);
}

export function createEventsOnChannelComplete({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: EventsOnChannelComplete;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createEventsOnChannelCompleteArray(instance);
  return createMessage(senderId, 262146, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface EventsOnProfileUpdate {
  UserId: string;
  ValuesAsJson: string;
}



export function readEventsOnProfileUpdateArray(instanceAsArray: any[]): EventsOnProfileUpdate {
  const instance: EventsOnProfileUpdate = {
    UserId: instanceAsArray[0],
    ValuesAsJson: instanceAsArray[1]
  };

  return instance;
}

export function createEventsOnProfileUpdateArray(instance: EventsOnProfileUpdate): any[] {
  const instanceAsArray: any[] = [
    instance.UserId,
    instance.ValuesAsJson
  ];

  return instanceAsArray;
}

export function readEventsOnProfileUpdate(raw: ArrayBuffer): EventsOnProfileUpdate {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readEventsOnProfileUpdateArray(instanceAsArray);
}

export function createEventsOnProfileUpdate({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: EventsOnProfileUpdate;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createEventsOnProfileUpdateArray(instance);
  return createMessage(senderId, 262145, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface FunctionParameter {
  Name: string;
  ValueType: ProtocolValueType;
  HasDefaultValue: boolean;
  DefaultValueJson: string;
  AllowedValues: string[];
}



export function readFunctionParameterArray(instanceAsArray: any[]): FunctionParameter {
  const instance: FunctionParameter = {
    Name: instanceAsArray[0],
    ValueType: readProtocolValueTypeArray(instanceAsArray[1]),
    HasDefaultValue: instanceAsArray[2],
    DefaultValueJson: instanceAsArray[3],
    AllowedValues: instanceAsArray[4]
  };

  return instance;
}

export function createFunctionParameterArray(instance: FunctionParameter): any[] {
  const instanceAsArray: any[] = [
    instance.Name,
    createProtocolValueTypeArray(instance.ValueType),
    instance.HasDefaultValue,
    instance.DefaultValueJson,
    instance.AllowedValues
  ];

  return instanceAsArray;
}

export function readFunctionParameter(raw: ArrayBuffer): FunctionParameter {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readFunctionParameterArray(instanceAsArray);
}

export function createFunctionParameter({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: FunctionParameter;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createFunctionParameterArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface GlobalState {
  Clients: Record<number, Context>;
  UIStreams: Record<string, UIStreamState>;
  AudioStreams: Record<string, AudioStreamState>;
  VideoStreams: Record<string, VideoStreamState>;
  TrackingStreams: Record<string, TrackingStreamState>;
  SpaceId: string;
  ChannelId: string;
  FirstUserId: string;
  ChannelUrl: string;
}



export function readGlobalStateArray(instanceAsArray: any[]): GlobalState {
  const instance: GlobalState = {
    Clients: Object.fromEntries(Object.entries(instanceAsArray[0]).map(([k, v]) => [k, readContextArray(v as any)])),
    UIStreams: Object.fromEntries(Object.entries(instanceAsArray[1]).map(([k, v]) => [k, readUIStreamStateArray(v as any)])),
    AudioStreams: Object.fromEntries(Object.entries(instanceAsArray[2]).map(([k, v]) => [k, readAudioStreamStateArray(v as any)])),
    VideoStreams: Object.fromEntries(Object.entries(instanceAsArray[3]).map(([k, v]) => [k, readVideoStreamStateArray(v as any)])),
    TrackingStreams: Object.fromEntries(Object.entries(instanceAsArray[4]).map(([k, v]) => [k, readTrackingStreamStateArray(v as any)])),
    SpaceId: instanceAsArray[5],
    ChannelId: instanceAsArray[6],
    FirstUserId: instanceAsArray[7],
    ChannelUrl: instanceAsArray[8]
  };

  return instance;
}

export function createGlobalStateArray(instance: GlobalState): any[] {
  const instanceAsArray: any[] = [
    Object.fromEntries(Object.entries(instance.Clients).map(([k, v]) => [k, createContextArray(v as any)])),
    Object.fromEntries(Object.entries(instance.UIStreams).map(([k, v]) => [k, createUIStreamStateArray(v as any)])),
    Object.fromEntries(Object.entries(instance.AudioStreams).map(([k, v]) => [k, createAudioStreamStateArray(v as any)])),
    Object.fromEntries(Object.entries(instance.VideoStreams).map(([k, v]) => [k, createVideoStreamStateArray(v as any)])),
    Object.fromEntries(Object.entries(instance.TrackingStreams).map(([k, v]) => [k, createTrackingStreamStateArray(v as any)])),
    instance.SpaceId,
    instance.ChannelId,
    instance.FirstUserId,
    instance.ChannelUrl
  ];

  return instanceAsArray;
}

export function readGlobalState(raw: ArrayBuffer): GlobalState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readGlobalStateArray(instanceAsArray);
}

export function createGlobalState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: GlobalState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createGlobalStateArray(instance);
  return createMessage(senderId, 65539, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface InvalidateVideoFrame {
  FrameNumber: bigint;
  TimeStampInUs: bigint;
}



export function readInvalidateVideoFrameArray(instanceAsArray: any[]): InvalidateVideoFrame {
  const instance: InvalidateVideoFrame = {
    FrameNumber: instanceAsArray[0],
    TimeStampInUs: instanceAsArray[1]
  };

  return instance;
}

export function createInvalidateVideoFrameArray(instance: InvalidateVideoFrame): any[] {
  const instanceAsArray: any[] = [
    instance.FrameNumber,
    instance.TimeStampInUs
  ];

  return instanceAsArray;
}

export function readInvalidateVideoFrame(raw: ArrayBuffer): InvalidateVideoFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readInvalidateVideoFrameArray(instanceAsArray);
}

export function createInvalidateVideoFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: InvalidateVideoFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createInvalidateVideoFrameArray(instance);
  return createMessage(senderId, 8388613, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface KeepaliveRequest {
}



export function readKeepaliveRequestArray(instanceAsArray: any[]): KeepaliveRequest {
  const instance: KeepaliveRequest = {

  };

  return instance;
}

export function createKeepaliveRequestArray(instance: KeepaliveRequest): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readKeepaliveRequest(raw: ArrayBuffer): KeepaliveRequest {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readKeepaliveRequestArray(instanceAsArray);
}

export function createKeepaliveRequest({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: KeepaliveRequest;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createKeepaliveRequestArray(instance);
  return createMessage(senderId, 131073, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface KeepaliveResponse {
}



export function readKeepaliveResponseArray(instanceAsArray: any[]): KeepaliveResponse {
  const instance: KeepaliveResponse = {

  };

  return instance;
}

export function createKeepaliveResponseArray(instance: KeepaliveResponse): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readKeepaliveResponse(raw: ArrayBuffer): KeepaliveResponse {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readKeepaliveResponseArray(instanceAsArray);
}

export function createKeepaliveResponse({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: KeepaliveResponse;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createKeepaliveResponseArray(instance);
  return createMessage(senderId, 131074, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnClientJoined {
  ClientContext: Context;
}



export function readOnClientJoinedArray(instanceAsArray: any[]): OnClientJoined {
  const instance: OnClientJoined = {
    ClientContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnClientJoinedArray(instance: OnClientJoined): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ClientContext)
  ];

  return instanceAsArray;
}

export function readOnClientJoined(raw: ArrayBuffer): OnClientJoined {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnClientJoinedArray(instanceAsArray);
}

export function createOnClientJoined({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnClientJoined;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnClientJoinedArray(instance);
  return createMessage(senderId, 65543, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnClientLeft {
  ClientContext: Context;
}



export function readOnClientLeftArray(instanceAsArray: any[]): OnClientLeft {
  const instance: OnClientLeft = {
    ClientContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnClientLeftArray(instance: OnClientLeft): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ClientContext)
  ];

  return instanceAsArray;
}

export function readOnClientLeft(raw: ArrayBuffer): OnClientLeft {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnClientLeftArray(instanceAsArray);
}

export function createOnClientLeft({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnClientLeft;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnClientLeftArray(instance);
  return createMessage(senderId, 65544, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnClientReady {
  ClientContext: Context;
}



export function readOnClientReadyArray(instanceAsArray: any[]): OnClientReady {
  const instance: OnClientReady = {
    ClientContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnClientReadyArray(instance: OnClientReady): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ClientContext)
  ];

  return instanceAsArray;
}

export function readOnClientReady(raw: ArrayBuffer): OnClientReady {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnClientReadyArray(instanceAsArray);
}

export function createOnClientReady({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnClientReady;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnClientReadyArray(instance);
  return createMessage(senderId, 65548, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnHostedServerExit {
  ServerSessionId: string;
  WasSuccessful: boolean;
}



export function readOnHostedServerExitArray(instanceAsArray: any[]): OnHostedServerExit {
  const instance: OnHostedServerExit = {
    ServerSessionId: instanceAsArray[0],
    WasSuccessful: instanceAsArray[1]
  };

  return instance;
}

export function createOnHostedServerExitArray(instance: OnHostedServerExit): any[] {
  const instanceAsArray: any[] = [
    instance.ServerSessionId,
    instance.WasSuccessful
  ];

  return instanceAsArray;
}

export function readOnHostedServerExit(raw: ArrayBuffer): OnHostedServerExit {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnHostedServerExitArray(instanceAsArray);
}

export function createOnHostedServerExit({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnHostedServerExit;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnHostedServerExitArray(instance);
  return createMessage(senderId, 65554, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnPluginReloaded {
  ServerContext: Context;
  PluginName: string;
}



export function readOnPluginReloadedArray(instanceAsArray: any[]): OnPluginReloaded {
  const instance: OnPluginReloaded = {
    ServerContext: readContextArray(instanceAsArray[0]),
    PluginName: instanceAsArray[1]
  };

  return instance;
}

export function createOnPluginReloadedArray(instance: OnPluginReloaded): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ServerContext),
    instance.PluginName
  ];

  return instanceAsArray;
}

export function readOnPluginReloaded(raw: ArrayBuffer): OnPluginReloaded {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnPluginReloadedArray(instanceAsArray);
}

export function createOnPluginReloaded({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnPluginReloaded;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnPluginReloadedArray(instance);
  return createMessage(senderId, 65551, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStarted {
  ServerContext: Context;
}



export function readOnServerStartedArray(instanceAsArray: any[]): OnServerStarted {
  const instance: OnServerStarted = {
    ServerContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnServerStartedArray(instance: OnServerStarted): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ServerContext)
  ];

  return instanceAsArray;
}

export function readOnServerStarted(raw: ArrayBuffer): OnServerStarted {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnServerStartedArray(instanceAsArray);
}

export function createOnServerStarted({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStarted;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnServerStartedArray(instance);
  return createMessage(senderId, 65545, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStatusPing {
  ServerContext: Context;
  Status: ServerStatus;
  UserCount: number;
  ClientCount: number;
  HumanClientCount: number;
  IdleTimeInSeconds: number;
  SentMessagesPerSecond: number;
  SentMessagesBandwidth: number;
  SentMessagesCount: number;
  ReceivedMessagesPerSecond: number;
  ReceivedMessagesBandwidth: number;
  ReceivedMessagesCount: number;
  ProcessCpuUsage: number;
  ProcessMemoryUsedMb: number;
  ManagedMemoryUsedMb: number;
}



export function readOnServerStatusPingArray(instanceAsArray: any[]): OnServerStatusPing {
  const instance: OnServerStatusPing = {
    ServerContext: readContextArray(instanceAsArray[0]),
    Status: instanceAsArray[1],
    UserCount: instanceAsArray[2],
    ClientCount: instanceAsArray[3],
    HumanClientCount: instanceAsArray[4],
    IdleTimeInSeconds: instanceAsArray[5],
    SentMessagesPerSecond: instanceAsArray[6],
    SentMessagesBandwidth: instanceAsArray[7],
    SentMessagesCount: instanceAsArray[8],
    ReceivedMessagesPerSecond: instanceAsArray[9],
    ReceivedMessagesBandwidth: instanceAsArray[10],
    ReceivedMessagesCount: instanceAsArray[11],
    ProcessCpuUsage: instanceAsArray[12],
    ProcessMemoryUsedMb: instanceAsArray[13],
    ManagedMemoryUsedMb: instanceAsArray[14]
  };

  return instance;
}

export function createOnServerStatusPingArray(instance: OnServerStatusPing): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ServerContext),
    instance.Status,
    instance.UserCount,
    instance.ClientCount,
    instance.HumanClientCount,
    instance.IdleTimeInSeconds,
    instance.SentMessagesPerSecond,
    instance.SentMessagesBandwidth,
    instance.SentMessagesCount,
    instance.ReceivedMessagesPerSecond,
    instance.ReceivedMessagesBandwidth,
    instance.ReceivedMessagesCount,
    instance.ProcessCpuUsage,
    instance.ProcessMemoryUsedMb,
    instance.ManagedMemoryUsedMb
  ];

  return instanceAsArray;
}

export function readOnServerStatusPing(raw: ArrayBuffer): OnServerStatusPing {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnServerStatusPingArray(instanceAsArray);
}

export function createOnServerStatusPing({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStatusPing;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnServerStatusPingArray(instance);
  return createMessage(senderId, 65540, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStopped {
  ServerContext: Context;
}



export function readOnServerStoppedArray(instanceAsArray: any[]): OnServerStopped {
  const instance: OnServerStopped = {
    ServerContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnServerStoppedArray(instance: OnServerStopped): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ServerContext)
  ];

  return instanceAsArray;
}

export function readOnServerStopped(raw: ArrayBuffer): OnServerStopped {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnServerStoppedArray(instanceAsArray);
}

export function createOnServerStopped({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStopped;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnServerStoppedArray(instance);
  return createMessage(senderId, 65546, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStopping {
  ServerContext: Context;
}



export function readOnServerStoppingArray(instanceAsArray: any[]): OnServerStopping {
  const instance: OnServerStopping = {
    ServerContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnServerStoppingArray(instance: OnServerStopping): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ServerContext)
  ];

  return instanceAsArray;
}

export function readOnServerStopping(raw: ArrayBuffer): OnServerStopping {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnServerStoppingArray(instanceAsArray);
}

export function createOnServerStopping({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStopping;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnServerStoppingArray(instance);
  return createMessage(senderId, 65547, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnUserJoined {
  ClientContext: Context;
}



export function readOnUserJoinedArray(instanceAsArray: any[]): OnUserJoined {
  const instance: OnUserJoined = {
    ClientContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnUserJoinedArray(instance: OnUserJoined): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ClientContext)
  ];

  return instanceAsArray;
}

export function readOnUserJoined(raw: ArrayBuffer): OnUserJoined {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnUserJoinedArray(instanceAsArray);
}

export function createOnUserJoined({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnUserJoined;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnUserJoinedArray(instance);
  return createMessage(senderId, 65541, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnUserLeft {
  ClientContext: Context;
}



export function readOnUserLeftArray(instanceAsArray: any[]): OnUserLeft {
  const instance: OnUserLeft = {
    ClientContext: readContextArray(instanceAsArray[0])
  };

  return instance;
}

export function createOnUserLeftArray(instance: OnUserLeft): any[] {
  const instanceAsArray: any[] = [
    createContextArray(instance.ClientContext)
  ];

  return instanceAsArray;
}

export function readOnUserLeft(raw: ArrayBuffer): OnUserLeft {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readOnUserLeftArray(instanceAsArray);
}

export function createOnUserLeft({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnUserLeft;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createOnUserLeftArray(instance);
  return createMessage(senderId, 65542, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ProtocolValueType {
  ValueType: FunctionValueType;
  KeyType: FunctionKeyType;
  IsArray: boolean;
  IsNullable: boolean;
}



export function readProtocolValueTypeArray(instanceAsArray: any[]): ProtocolValueType {
  const instance: ProtocolValueType = {
    ValueType: instanceAsArray[0],
    KeyType: instanceAsArray[1],
    IsArray: instanceAsArray[2],
    IsNullable: instanceAsArray[3]
  };

  return instance;
}

export function createProtocolValueTypeArray(instance: ProtocolValueType): any[] {
  const instanceAsArray: any[] = [
    instance.ValueType,
    instance.KeyType,
    instance.IsArray,
    instance.IsNullable
  ];

  return instanceAsArray;
}

export function readProtocolValueType(raw: ArrayBuffer): ProtocolValueType {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readProtocolValueTypeArray(instanceAsArray);
}

export function createProtocolValueType({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ProtocolValueType;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createProtocolValueTypeArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface RequestIdrVideoFrame {
}



export function readRequestIdrVideoFrameArray(instanceAsArray: any[]): RequestIdrVideoFrame {
  const instance: RequestIdrVideoFrame = {

  };

  return instance;
}

export function createRequestIdrVideoFrameArray(instance: RequestIdrVideoFrame): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readRequestIdrVideoFrame(raw: ArrayBuffer): RequestIdrVideoFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readRequestIdrVideoFrameArray(instanceAsArray);
}

export function createRequestIdrVideoFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: RequestIdrVideoFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createRequestIdrVideoFrameArray(instance);
  return createMessage(senderId, 8388612, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface SceneArray {
  SerializerType: number;
  Type: string;
  SubId: string;
  ElementOffset: number;
  ElementCount: number;
  ByteOffset: number;
  TypeSize: number;
  StrideSize: number;
  ByteArray: Uint8Array;
}



export function readSceneArrayArray(instanceAsArray: any[]): SceneArray {
  const instance: SceneArray = {
    SerializerType: instanceAsArray[0],
    Type: instanceAsArray[1],
    SubId: instanceAsArray[2],
    ElementOffset: instanceAsArray[3],
    ElementCount: instanceAsArray[4],
    ByteOffset: instanceAsArray[5],
    TypeSize: instanceAsArray[6],
    StrideSize: instanceAsArray[7],
    ByteArray: instanceAsArray[8]
  };

  return instance;
}

export function createSceneArrayArray(instance: SceneArray): any[] {
  const instanceAsArray: any[] = [
    instance.SerializerType,
    instance.Type,
    instance.SubId,
    instance.ElementOffset,
    instance.ElementCount,
    instance.ByteOffset,
    instance.TypeSize,
    instance.StrideSize,
    instance.ByteArray
  ];

  return instanceAsArray;
}

export function readSceneArray(raw: ArrayBuffer): SceneArray {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readSceneArrayArray(instanceAsArray);
}

export function createSceneArray({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: SceneArray;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createSceneArrayArray(instance);
  return createMessage(senderId, 33554434, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface SceneMesh {
  Vertices: number[];
}



export function readSceneMeshArray(instanceAsArray: any[]): SceneMesh {
  const instance: SceneMesh = {
    Vertices: instanceAsArray[0]
  };

  return instance;
}

export function createSceneMeshArray(instance: SceneMesh): any[] {
  const instanceAsArray: any[] = [
    instance.Vertices
  ];

  return instanceAsArray;
}

export function readSceneMesh(raw: ArrayBuffer): SceneMesh {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readSceneMeshArray(instanceAsArray);
}

export function createSceneMesh({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: SceneMesh;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createSceneMeshArray(instance);
  return createMessage(senderId, 33554433, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerInit {
  IkonBackendUrl: string;
  IkonBackendToken: string;
  SpaceId: string;
  ChannelId: string;
  Plugins: ServerPluginInit[];
  PrimaryUserId: string;
  ChannelTemplateId: string;
  ChannelUrl: string;
}



export function readServerInitArray(instanceAsArray: any[]): ServerInit {
  const instance: ServerInit = {
    IkonBackendUrl: instanceAsArray[0],
    IkonBackendToken: instanceAsArray[1],
    SpaceId: instanceAsArray[2],
    ChannelId: instanceAsArray[3],
    Plugins: instanceAsArray[4].map(readServerPluginInitArray),
    PrimaryUserId: instanceAsArray[5],
    ChannelTemplateId: instanceAsArray[6],
    ChannelUrl: instanceAsArray[7]
  };

  return instance;
}

export function createServerInitArray(instance: ServerInit): any[] {
  const instanceAsArray: any[] = [
    instance.IkonBackendUrl,
    instance.IkonBackendToken,
    instance.SpaceId,
    instance.ChannelId,
    instance.Plugins.map(createServerPluginInitArray),
    instance.PrimaryUserId,
    instance.ChannelTemplateId,
    instance.ChannelUrl
  ];

  return instanceAsArray;
}

export function readServerInit(raw: ArrayBuffer): ServerInit {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readServerInitArray(instanceAsArray);
}

export function createServerInit({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerInit;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createServerInitArray(instance);
  return createMessage(senderId, 65550, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 5, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerPluginInit {
  Enabled: boolean;
  TypeName: string;
  ConfigFilePath: string;
  ConfigJsonContent: string;
  ExtraConfigs: ServerPluginInitExtraConfig[];
  DllPath: string;
  DllContent: Uint8Array;
}



export function readServerPluginInitArray(instanceAsArray: any[]): ServerPluginInit {
  const instance: ServerPluginInit = {
    Enabled: instanceAsArray[0],
    TypeName: instanceAsArray[1],
    ConfigFilePath: instanceAsArray[2],
    ConfigJsonContent: instanceAsArray[3],
    ExtraConfigs: instanceAsArray[4].map(readServerPluginInitExtraConfigArray),
    DllPath: instanceAsArray[5],
    DllContent: instanceAsArray[6]
  };

  return instance;
}

export function createServerPluginInitArray(instance: ServerPluginInit): any[] {
  const instanceAsArray: any[] = [
    instance.Enabled,
    instance.TypeName,
    instance.ConfigFilePath,
    instance.ConfigJsonContent,
    instance.ExtraConfigs.map(createServerPluginInitExtraConfigArray),
    instance.DllPath,
    instance.DllContent
  ];

  return instanceAsArray;
}

export function readServerPluginInit(raw: ArrayBuffer): ServerPluginInit {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readServerPluginInitArray(instanceAsArray);
}

export function createServerPluginInit({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerPluginInit;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createServerPluginInitArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerPluginInitExtraConfig {
  FilePath: string;
  Content: string;
}



export function readServerPluginInitExtraConfigArray(instanceAsArray: any[]): ServerPluginInitExtraConfig {
  const instance: ServerPluginInitExtraConfig = {
    FilePath: instanceAsArray[0],
    Content: instanceAsArray[1]
  };

  return instance;
}

export function createServerPluginInitExtraConfigArray(instance: ServerPluginInitExtraConfig): any[] {
  const instanceAsArray: any[] = [
    instance.FilePath,
    instance.Content
  ];

  return instanceAsArray;
}

export function readServerPluginInitExtraConfig(raw: ArrayBuffer): ServerPluginInitExtraConfig {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readServerPluginInitExtraConfigArray(instanceAsArray);
}

export function createServerPluginInitExtraConfig({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerPluginInitExtraConfig;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createServerPluginInitExtraConfigArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerStart {
  HostServerSessionId: string;
  ConfigJsonContent: string;
}



export function readServerStartArray(instanceAsArray: any[]): ServerStart {
  const instance: ServerStart = {
    HostServerSessionId: instanceAsArray[0],
    ConfigJsonContent: instanceAsArray[1]
  };

  return instance;
}

export function createServerStartArray(instance: ServerStart): any[] {
  const instanceAsArray: any[] = [
    instance.HostServerSessionId,
    instance.ConfigJsonContent
  ];

  return instanceAsArray;
}

export function readServerStart(raw: ArrayBuffer): ServerStart {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readServerStartArray(instanceAsArray);
}

export function createServerStart({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerStart;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createServerStartArray(instance);
  return createMessage(senderId, 65552, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerStop {
  HostServerSessionId: string;
  TargetServerSessionId: string;
}



export function readServerStopArray(instanceAsArray: any[]): ServerStop {
  const instance: ServerStop = {
    HostServerSessionId: instanceAsArray[0],
    TargetServerSessionId: instanceAsArray[1]
  };

  return instance;
}

export function createServerStopArray(instance: ServerStop): any[] {
  const instanceAsArray: any[] = [
    instance.HostServerSessionId,
    instance.TargetServerSessionId
  ];

  return instanceAsArray;
}

export function readServerStop(raw: ArrayBuffer): ServerStop {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readServerStopArray(instanceAsArray);
}

export function createServerStop({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerStop;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createServerStopArray(instance);
  return createMessage(senderId, 65553, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingFrame {
  TimestampInUs: bigint;
  DurationInUs: number;
  FaceBlendshapes: number[];
  FaceTransformationMatrix: number[];
}



export function readTrackingFrameArray(instanceAsArray: any[]): TrackingFrame {
  const instance: TrackingFrame = {
    TimestampInUs: instanceAsArray[0],
    DurationInUs: instanceAsArray[1],
    FaceBlendshapes: instanceAsArray[2],
    FaceTransformationMatrix: instanceAsArray[3]
  };

  return instance;
}

export function createTrackingFrameArray(instance: TrackingFrame): any[] {
  const instanceAsArray: any[] = [
    instance.TimestampInUs,
    instance.DurationInUs,
    instance.FaceBlendshapes,
    instance.FaceTransformationMatrix
  ];

  return instanceAsArray;
}

export function readTrackingFrame(raw: ArrayBuffer): TrackingFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readTrackingFrameArray(instanceAsArray);
}

export function createTrackingFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createTrackingFrameArray(instance);
  return createMessage(senderId, 16777219, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingStreamBegin {
  Category: string;
  Type: TrackingType;
  FaceBlendshapes: string[];
}



export function readTrackingStreamBeginArray(instanceAsArray: any[]): TrackingStreamBegin {
  const instance: TrackingStreamBegin = {
    Category: instanceAsArray[0],
    Type: instanceAsArray[1],
    FaceBlendshapes: instanceAsArray[2]
  };

  return instance;
}

export function createTrackingStreamBeginArray(instance: TrackingStreamBegin): any[] {
  const instanceAsArray: any[] = [
    instance.Category,
    instance.Type,
    instance.FaceBlendshapes
  ];

  return instanceAsArray;
}

export function readTrackingStreamBegin(raw: ArrayBuffer): TrackingStreamBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readTrackingStreamBeginArray(instanceAsArray);
}

export function createTrackingStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createTrackingStreamBeginArray(instance);
  return createMessage(senderId, 16777217, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingStreamEnd {
}



export function readTrackingStreamEndArray(instanceAsArray: any[]): TrackingStreamEnd {
  const instance: TrackingStreamEnd = {

  };

  return instance;
}

export function createTrackingStreamEndArray(instance: TrackingStreamEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readTrackingStreamEnd(raw: ArrayBuffer): TrackingStreamEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readTrackingStreamEndArray(instanceAsArray);
}

export function createTrackingStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createTrackingStreamEndArray(instance);
  return createMessage(senderId, 16777218, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: TrackingStreamBegin;
}



export function readTrackingStreamStateArray(instanceAsArray: any[]): TrackingStreamState {
  const instance: TrackingStreamState = {
    StreamId: instanceAsArray[0],
    ClientSessionId: instanceAsArray[1],
    TrackId: instanceAsArray[2],
    Info: readTrackingStreamBeginArray(instanceAsArray[3])
  };

  return instance;
}

export function createTrackingStreamStateArray(instance: TrackingStreamState): any[] {
  const instanceAsArray: any[] = [
    instance.StreamId,
    instance.ClientSessionId,
    instance.TrackId,
    createTrackingStreamBeginArray(instance.Info)
  ];

  return instanceAsArray;
}

export function readTrackingStreamState(raw: ArrayBuffer): TrackingStreamState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readTrackingStreamStateArray(instanceAsArray);
}

export function createTrackingStreamState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingStreamState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createTrackingStreamStateArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIAutocomplete {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  Options: UIAutocompleteOption[];
  UpdateActionId: string;
  MinCount: number;
  MaxCount: number;
  Color: UIColor;
  Variant: UIInputVariant;
}



export function readUIAutocompleteArray(instanceAsArray: any[]): UIAutocomplete {
  const instance: UIAutocomplete = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    Options: instanceAsArray[4].map(readUIAutocompleteOptionArray),
    UpdateActionId: instanceAsArray[5],
    MinCount: instanceAsArray[6],
    MaxCount: instanceAsArray[7],
    Color: instanceAsArray[8],
    Variant: instanceAsArray[9]
  };

  return instance;
}

export function createUIAutocompleteArray(instance: UIAutocomplete): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.Options.map(createUIAutocompleteOptionArray),
    instance.UpdateActionId,
    instance.MinCount,
    instance.MaxCount,
    instance.Color,
    instance.Variant
  ];

  return instanceAsArray;
}

export function readUIAutocomplete(raw: ArrayBuffer): UIAutocomplete {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIAutocompleteArray(instanceAsArray);
}

export function createUIAutocomplete({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIAutocomplete;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIAutocompleteArray(instance);
  return createMessage(senderId, 2097184, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIAutocompleteOption {
  Name: string;
  Value: string;
}



export function readUIAutocompleteOptionArray(instanceAsArray: any[]): UIAutocompleteOption {
  const instance: UIAutocompleteOption = {
    Name: instanceAsArray[0],
    Value: instanceAsArray[1]
  };

  return instance;
}

export function createUIAutocompleteOptionArray(instance: UIAutocompleteOption): any[] {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.Value
  ];

  return instanceAsArray;
}

export function readUIAutocompleteOption(raw: ArrayBuffer): UIAutocompleteOption {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIAutocompleteOptionArray(instanceAsArray);
}

export function createUIAutocompleteOption({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIAutocompleteOption;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIAutocompleteOptionArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIBadge {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Text: string;
  Color: UIColor;
  ClickActionId: string;
  Variant: UIBadgeVariant;
}



export function readUIBadgeArray(instanceAsArray: any[]): UIBadge {
  const instance: UIBadge = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Text: instanceAsArray[3],
    Color: instanceAsArray[4],
    ClickActionId: instanceAsArray[5],
    Variant: instanceAsArray[6]
  };

  return instance;
}

export function createUIBadgeArray(instance: UIBadge): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Text,
    instance.Color,
    instance.ClickActionId,
    instance.Variant
  ];

  return instanceAsArray;
}

export function readUIBadge(raw: ArrayBuffer): UIBadge {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIBadgeArray(instanceAsArray);
}

export function createUIBadge({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIBadge;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIBadgeArray(instance);
  return createMessage(senderId, 2097169, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIButton {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Text: string;
  Icon: UIIconType;
  Color: UIColor;
  ClickActionId: string;
  Variant: UIButtonVariant;
}



export function readUIButtonArray(instanceAsArray: any[]): UIButton {
  const instance: UIButton = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Text: instanceAsArray[3],
    Icon: instanceAsArray[4],
    Color: instanceAsArray[5],
    ClickActionId: instanceAsArray[6],
    Variant: instanceAsArray[7]
  };

  return instance;
}

export function createUIButtonArray(instance: UIButton): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Text,
    instance.Icon,
    instance.Color,
    instance.ClickActionId,
    instance.Variant
  ];

  return instanceAsArray;
}

export function readUIButton(raw: ArrayBuffer): UIButton {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIButtonArray(instanceAsArray);
}

export function createUIButton({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIButton;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIButtonArray(instance);
  return createMessage(senderId, 2097165, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIButtonBegin {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Color: UIColor;
  ClickActionId: string;
  Variant: UIButtonVariant;
}



export function readUIButtonBeginArray(instanceAsArray: any[]): UIButtonBegin {
  const instance: UIButtonBegin = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Color: instanceAsArray[3],
    ClickActionId: instanceAsArray[4],
    Variant: instanceAsArray[5]
  };

  return instance;
}

export function createUIButtonBeginArray(instance: UIButtonBegin): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Color,
    instance.ClickActionId,
    instance.Variant
  ];

  return instanceAsArray;
}

export function readUIButtonBegin(raw: ArrayBuffer): UIButtonBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIButtonBeginArray(instanceAsArray);
}

export function createUIButtonBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIButtonBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIButtonBeginArray(instance);
  return createMessage(senderId, 2097177, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIButtonEnd {
}



export function readUIButtonEndArray(instanceAsArray: any[]): UIButtonEnd {
  const instance: UIButtonEnd = {

  };

  return instance;
}

export function createUIButtonEndArray(instance: UIButtonEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIButtonEnd(raw: ArrayBuffer): UIButtonEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIButtonEndArray(instanceAsArray);
}

export function createUIButtonEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIButtonEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIButtonEndArray(instance);
  return createMessage(senderId, 2097178, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UICheckbox {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  UpdateActionId: string;
  Selected: boolean;
}



export function readUICheckboxArray(instanceAsArray: any[]): UICheckbox {
  const instance: UICheckbox = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    UpdateActionId: instanceAsArray[4],
    Selected: instanceAsArray[5]
  };

  return instance;
}

export function createUICheckboxArray(instance: UICheckbox): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.UpdateActionId,
    instance.Selected
  ];

  return instanceAsArray;
}

export function readUICheckbox(raw: ArrayBuffer): UICheckbox {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUICheckboxArray(instanceAsArray);
}

export function createUICheckbox({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UICheckbox;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUICheckboxArray(instance);
  return createMessage(senderId, 2097185, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContainerBegin {
  ContainerId: string;
  UserId: string;
  CreatedAt: string;
  UpdatedAt: string;
  AlternativeText: string;
  IsTransient: boolean;
  IsHistory: boolean;
  IsUpdate: boolean;
  GroupId: number;
  SortingId: number;
  IsStable: boolean;
  Visibility: UIVisibilityType;
  PreciseCreatedAt: bigint;
}



export function readUIContainerBeginArray(instanceAsArray: any[]): UIContainerBegin {
  const instance: UIContainerBegin = {
    ContainerId: instanceAsArray[0],
    UserId: instanceAsArray[1],
    CreatedAt: instanceAsArray[2],
    UpdatedAt: instanceAsArray[3],
    AlternativeText: instanceAsArray[4],
    IsTransient: instanceAsArray[5],
    IsHistory: instanceAsArray[6],
    IsUpdate: instanceAsArray[7],
    GroupId: instanceAsArray[8],
    SortingId: instanceAsArray[9],
    IsStable: instanceAsArray[10],
    Visibility: instanceAsArray[11],
    PreciseCreatedAt: instanceAsArray[12]
  };

  return instance;
}

export function createUIContainerBeginArray(instance: UIContainerBegin): any[] {
  const instanceAsArray: any[] = [
    instance.ContainerId,
    instance.UserId,
    instance.CreatedAt,
    instance.UpdatedAt,
    instance.AlternativeText,
    instance.IsTransient,
    instance.IsHistory,
    instance.IsUpdate,
    instance.GroupId,
    instance.SortingId,
    instance.IsStable,
    instance.Visibility,
    instance.PreciseCreatedAt
  ];

  return instanceAsArray;
}

export function readUIContainerBegin(raw: ArrayBuffer): UIContainerBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIContainerBeginArray(instanceAsArray);
}

export function createUIContainerBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContainerBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIContainerBeginArray(instance);
  return createMessage(senderId, 2097155, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContainerDelete {
}



export function readUIContainerDeleteArray(instanceAsArray: any[]): UIContainerDelete {
  const instance: UIContainerDelete = {

  };

  return instance;
}

export function createUIContainerDeleteArray(instance: UIContainerDelete): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIContainerDelete(raw: ArrayBuffer): UIContainerDelete {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIContainerDeleteArray(instanceAsArray);
}

export function createUIContainerDelete({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContainerDelete;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIContainerDeleteArray(instance);
  return createMessage(senderId, 2097179, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContainerEnd {
}



export function readUIContainerEndArray(instanceAsArray: any[]): UIContainerEnd {
  const instance: UIContainerEnd = {

  };

  return instance;
}

export function createUIContainerEndArray(instance: UIContainerEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIContainerEnd(raw: ArrayBuffer): UIContainerEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIContainerEndArray(instanceAsArray);
}

export function createUIContainerEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContainerEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIContainerEndArray(instance);
  return createMessage(senderId, 2097156, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContentLink {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Type: UIContentLinkType;
  Code: string;
}



export function readUIContentLinkArray(instanceAsArray: any[]): UIContentLink {
  const instance: UIContentLink = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Type: instanceAsArray[3],
    Code: instanceAsArray[4]
  };

  return instance;
}

export function createUIContentLinkArray(instance: UIContentLink): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Type,
    instance.Code
  ];

  return instanceAsArray;
}

export function readUIContentLink(raw: ArrayBuffer): UIContentLink {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIContentLinkArray(instanceAsArray);
}

export function createUIContentLink({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContentLink;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIContentLinkArray(instance);
  return createMessage(senderId, 2097170, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIElement {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  Description: string;
  ArgumentsJson: string;
  ActionIds: Record<string, Action>;
  Payloads: Record<string, UIPayload>;
}



export function readUIElementArray(instanceAsArray: any[]): UIElement {
  const instance: UIElement = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    Description: instanceAsArray[4],
    ArgumentsJson: instanceAsArray[5],
    ActionIds: Object.fromEntries(Object.entries(instanceAsArray[6]).map(([k, v]) => [k, readActionArray(v as any)])),
    Payloads: Object.fromEntries(Object.entries(instanceAsArray[7]).map(([k, v]) => [k, readUIPayloadArray(v as any)]))
  };

  return instance;
}

export function createUIElementArray(instance: UIElement): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.Description,
    instance.ArgumentsJson,
    Object.fromEntries(Object.entries(instance.ActionIds).map(([k, v]) => [k, createActionArray(v as any)])),
    Object.fromEntries(Object.entries(instance.Payloads).map(([k, v]) => [k, createUIPayloadArray(v as any)]))
  ];

  return instanceAsArray;
}

export function readUIElement(raw: ArrayBuffer): UIElement {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIElementArray(instanceAsArray);
}

export function createUIElement({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIElement;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIElementArray(instance);
  return createMessage(senderId, 2097187, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFile {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  SecondaryText: string;
  Type: string;
  Mime: string;
  AllowedMimeTypes: string[];
  MaxSize: number;
  FileInfo: UIFileInfo;
  OpenActionId: string;
  UploadActionId: string;
  RemoveActionId: string;
}



export function readUIFileArray(instanceAsArray: any[]): UIFile {
  const instance: UIFile = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    SecondaryText: instanceAsArray[4],
    Type: instanceAsArray[5],
    Mime: instanceAsArray[6],
    AllowedMimeTypes: instanceAsArray[7],
    MaxSize: instanceAsArray[8],
    FileInfo: readUIFileInfoArray(instanceAsArray[9]),
    OpenActionId: instanceAsArray[10],
    UploadActionId: instanceAsArray[11],
    RemoveActionId: instanceAsArray[12]
  };

  return instance;
}

export function createUIFileArray(instance: UIFile): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.SecondaryText,
    instance.Type,
    instance.Mime,
    instance.AllowedMimeTypes,
    instance.MaxSize,
    createUIFileInfoArray(instance.FileInfo),
    instance.OpenActionId,
    instance.UploadActionId,
    instance.RemoveActionId
  ];

  return instanceAsArray;
}

export function readUIFile(raw: ArrayBuffer): UIFile {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIFileArray(instanceAsArray);
}

export function createUIFile({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFile;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIFileArray(instance);
  return createMessage(senderId, 2097168, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFileInfo {
  Id: string;
  Name: string;
  FileName: string;
  CreatedAt: string;
  Size: number;
  Url: string;
}



export function readUIFileInfoArray(instanceAsArray: any[]): UIFileInfo {
  const instance: UIFileInfo = {
    Id: instanceAsArray[0],
    Name: instanceAsArray[1],
    FileName: instanceAsArray[2],
    CreatedAt: instanceAsArray[3],
    Size: instanceAsArray[4],
    Url: instanceAsArray[5]
  };

  return instance;
}

export function createUIFileInfoArray(instance: UIFileInfo): any[] {
  const instanceAsArray: any[] = [
    instance.Id,
    instance.Name,
    instance.FileName,
    instance.CreatedAt,
    instance.Size,
    instance.Url
  ];

  return instanceAsArray;
}

export function readUIFileInfo(raw: ArrayBuffer): UIFileInfo {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIFileInfoArray(instanceAsArray);
}

export function createUIFileInfo({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFileInfo;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIFileInfoArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFileUploadSectionBegin {
  Gap: number;
  UploadActionId: string;
  AllowedMimeTypes: string[];
  MaxSize: number;
}



export function readUIFileUploadSectionBeginArray(instanceAsArray: any[]): UIFileUploadSectionBegin {
  const instance: UIFileUploadSectionBegin = {
    Gap: instanceAsArray[0],
    UploadActionId: instanceAsArray[1],
    AllowedMimeTypes: instanceAsArray[2],
    MaxSize: instanceAsArray[3]
  };

  return instance;
}

export function createUIFileUploadSectionBeginArray(instance: UIFileUploadSectionBegin): any[] {
  const instanceAsArray: any[] = [
    instance.Gap,
    instance.UploadActionId,
    instance.AllowedMimeTypes,
    instance.MaxSize
  ];

  return instanceAsArray;
}

export function readUIFileUploadSectionBegin(raw: ArrayBuffer): UIFileUploadSectionBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIFileUploadSectionBeginArray(instanceAsArray);
}

export function createUIFileUploadSectionBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFileUploadSectionBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIFileUploadSectionBeginArray(instance);
  return createMessage(senderId, 2097174, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFileUploadSectionEnd {
}



export function readUIFileUploadSectionEndArray(instanceAsArray: any[]): UIFileUploadSectionEnd {
  const instance: UIFileUploadSectionEnd = {

  };

  return instance;
}

export function createUIFileUploadSectionEndArray(instance: UIFileUploadSectionEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIFileUploadSectionEnd(raw: ArrayBuffer): UIFileUploadSectionEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIFileUploadSectionEndArray(instanceAsArray);
}

export function createUIFileUploadSectionEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFileUploadSectionEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIFileUploadSectionEndArray(instance);
  return createMessage(senderId, 2097175, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIHeader {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Text: string;
  SubText: string;
  Level: UIHeaderLevel;
}



export function readUIHeaderArray(instanceAsArray: any[]): UIHeader {
  const instance: UIHeader = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Text: instanceAsArray[3],
    SubText: instanceAsArray[4],
    Level: instanceAsArray[5]
  };

  return instance;
}

export function createUIHeaderArray(instance: UIHeader): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Text,
    instance.SubText,
    instance.Level
  ];

  return instanceAsArray;
}

export function readUIHeader(raw: ArrayBuffer): UIHeader {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIHeaderArray(instanceAsArray);
}

export function createUIHeader({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIHeader;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIHeaderArray(instance);
  return createMessage(senderId, 2097163, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIIcon {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Icon: UIIconType;
  Color: UIColor;
}



export function readUIIconArray(instanceAsArray: any[]): UIIcon {
  const instance: UIIcon = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Icon: instanceAsArray[3],
    Color: instanceAsArray[4]
  };

  return instance;
}

export function createUIIconArray(instance: UIIcon): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Icon,
    instance.Color
  ];

  return instanceAsArray;
}

export function readUIIcon(raw: ArrayBuffer): UIIcon {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIIconArray(instanceAsArray);
}

export function createUIIcon({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIIcon;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIIconArray(instance);
  return createMessage(senderId, 2097173, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIIconButton {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Icon: UIIconType;
  Color: UIColor;
  ClickActionId: string;
}



export function readUIIconButtonArray(instanceAsArray: any[]): UIIconButton {
  const instance: UIIconButton = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Icon: instanceAsArray[3],
    Color: instanceAsArray[4],
    ClickActionId: instanceAsArray[5]
  };

  return instance;
}

export function createUIIconButtonArray(instance: UIIconButton): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Icon,
    instance.Color,
    instance.ClickActionId
  ];

  return instanceAsArray;
}

export function readUIIconButton(raw: ArrayBuffer): UIIconButton {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIIconButtonArray(instanceAsArray);
}

export function createUIIconButton({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIIconButton;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIIconButtonArray(instance);
  return createMessage(senderId, 2097166, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIImage {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  Url: string;
  Mime: string;
  Data: Uint8Array;
  ClickActionId: string;
}



export function readUIImageArray(instanceAsArray: any[]): UIImage {
  const instance: UIImage = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    Url: instanceAsArray[4],
    Mime: instanceAsArray[5],
    Data: instanceAsArray[6],
    ClickActionId: instanceAsArray[7]
  };

  return instance;
}

export function createUIImageArray(instance: UIImage): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.Url,
    instance.Mime,
    instance.Data,
    instance.ClickActionId
  ];

  return instanceAsArray;
}

export function readUIImage(raw: ArrayBuffer): UIImage {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIImageArray(instanceAsArray);
}

export function createUIImage({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIImage;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIImageArray(instance);
  return createMessage(senderId, 2097167, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIInputText {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  UpdateActionId: string;
  Color: UIColor;
  Variant: UIInputVariant;
}



export function readUIInputTextArray(instanceAsArray: any[]): UIInputText {
  const instance: UIInputText = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    UpdateActionId: instanceAsArray[4],
    Color: instanceAsArray[5],
    Variant: instanceAsArray[6]
  };

  return instance;
}

export function createUIInputTextArray(instance: UIInputText): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.UpdateActionId,
    instance.Color,
    instance.Variant
  ];

  return instanceAsArray;
}

export function readUIInputText(raw: ArrayBuffer): UIInputText {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIInputTextArray(instanceAsArray);
}

export function createUIInputText({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIInputText;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIInputTextArray(instance);
  return createMessage(senderId, 2097180, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIListBegin {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Type: UIListType;
}



export function readUIListBeginArray(instanceAsArray: any[]): UIListBegin {
  const instance: UIListBegin = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Type: instanceAsArray[3]
  };

  return instance;
}

export function createUIListBeginArray(instance: UIListBegin): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Type
  ];

  return instanceAsArray;
}

export function readUIListBegin(raw: ArrayBuffer): UIListBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIListBeginArray(instanceAsArray);
}

export function createUIListBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIListBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIListBeginArray(instance);
  return createMessage(senderId, 2097159, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIListEnd {
}



export function readUIListEndArray(instanceAsArray: any[]): UIListEnd {
  const instance: UIListEnd = {

  };

  return instance;
}

export function createUIListEndArray(instance: UIListEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIListEnd(raw: ArrayBuffer): UIListEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIListEndArray(instanceAsArray);
}

export function createUIListEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIListEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIListEndArray(instance);
  return createMessage(senderId, 2097161, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIListItem {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  Text: string;
}



export function readUIListItemArray(instanceAsArray: any[]): UIListItem {
  const instance: UIListItem = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    Text: instanceAsArray[4]
  };

  return instance;
}

export function createUIListItemArray(instance: UIListItem): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.Text
  ];

  return instanceAsArray;
}

export function readUIListItem(raw: ArrayBuffer): UIListItem {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIListItemArray(instanceAsArray);
}

export function createUIListItem({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIListItem;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIListItemArray(instance);
  return createMessage(senderId, 2097160, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIMap {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  Marker: UIMapMarker;
  Markers: UIMapMarker[];
}



export function readUIMapArray(instanceAsArray: any[]): UIMap {
  const instance: UIMap = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    Marker: readUIMapMarkerArray(instanceAsArray[4]),
    Markers: instanceAsArray[5].map(readUIMapMarkerArray)
  };

  return instance;
}

export function createUIMapArray(instance: UIMap): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    createUIMapMarkerArray(instance.Marker),
    instance.Markers.map(createUIMapMarkerArray)
  ];

  return instanceAsArray;
}

export function readUIMap(raw: ArrayBuffer): UIMap {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIMapArray(instanceAsArray);
}

export function createUIMap({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIMap;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIMapArray(instance);
  return createMessage(senderId, 2097171, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIMapMarker {
  Title: string;
  Latitude: number;
  Longitude: number;
}



export function readUIMapMarkerArray(instanceAsArray: any[]): UIMapMarker {
  const instance: UIMapMarker = {
    Title: instanceAsArray[0],
    Latitude: instanceAsArray[1],
    Longitude: instanceAsArray[2]
  };

  return instance;
}

export function createUIMapMarkerArray(instance: UIMapMarker): any[] {
  const instanceAsArray: any[] = [
    instance.Title,
    instance.Latitude,
    instance.Longitude
  ];

  return instanceAsArray;
}

export function readUIMapMarker(raw: ArrayBuffer): UIMapMarker {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIMapMarkerArray(instanceAsArray);
}

export function createUIMapMarker({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIMapMarker;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIMapMarkerArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIMaterialSymbol {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  Color: UIColor;
}



export function readUIMaterialSymbolArray(instanceAsArray: any[]): UIMaterialSymbol {
  const instance: UIMaterialSymbol = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    Color: instanceAsArray[4]
  };

  return instance;
}

export function createUIMaterialSymbolArray(instance: UIMaterialSymbol): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.Color
  ];

  return instanceAsArray;
}

export function readUIMaterialSymbol(raw: ArrayBuffer): UIMaterialSymbol {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIMaterialSymbolArray(instanceAsArray);
}

export function createUIMaterialSymbol({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIMaterialSymbol;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIMaterialSymbolArray(instance);
  return createMessage(senderId, 2097176, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIPayload {
  MimeType: string;
  Value: Uint8Array;
}



export function readUIPayloadArray(instanceAsArray: any[]): UIPayload {
  const instance: UIPayload = {
    MimeType: instanceAsArray[0],
    Value: instanceAsArray[1]
  };

  return instance;
}

export function createUIPayloadArray(instance: UIPayload): any[] {
  const instanceAsArray: any[] = [
    instance.MimeType,
    instance.Value
  ];

  return instanceAsArray;
}

export function readUIPayload(raw: ArrayBuffer): UIPayload {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIPayloadArray(instanceAsArray);
}

export function createUIPayload({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIPayload;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIPayloadArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIProgressBar {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Percentage: number;
}



export function readUIProgressBarArray(instanceAsArray: any[]): UIProgressBar {
  const instance: UIProgressBar = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Percentage: instanceAsArray[3]
  };

  return instance;
}

export function createUIProgressBarArray(instance: UIProgressBar): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Percentage
  ];

  return instanceAsArray;
}

export function readUIProgressBar(raw: ArrayBuffer): UIProgressBar {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIProgressBarArray(instanceAsArray);
}

export function createUIProgressBar({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIProgressBar;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIProgressBarArray(instance);
  return createMessage(senderId, 2097181, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIQS {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Name: string;
  EventActionId: string;
  ArgumentsJson: Record<string, string>;
}



export function readUIQSArray(instanceAsArray: any[]): UIQS {
  const instance: UIQS = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Name: instanceAsArray[3],
    EventActionId: instanceAsArray[4],
    ArgumentsJson: instanceAsArray[5]
  };

  return instance;
}

export function createUIQSArray(instance: UIQS): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Name,
    instance.EventActionId,
    instance.ArgumentsJson
  ];

  return instanceAsArray;
}

export function readUIQS(raw: ArrayBuffer): UIQS {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIQSArray(instanceAsArray);
}

export function createUIQS({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIQS;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIQSArray(instance);
  return createMessage(senderId, 2097186, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UISectionBegin {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Type: UISectionType;
  Gap: number;
  ClickActionId: string;
}



export function readUISectionBeginArray(instanceAsArray: any[]): UISectionBegin {
  const instance: UISectionBegin = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Type: instanceAsArray[3],
    Gap: instanceAsArray[4],
    ClickActionId: instanceAsArray[5]
  };

  return instance;
}

export function createUISectionBeginArray(instance: UISectionBegin): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Type,
    instance.Gap,
    instance.ClickActionId
  ];

  return instanceAsArray;
}

export function readUISectionBegin(raw: ArrayBuffer): UISectionBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUISectionBeginArray(instanceAsArray);
}

export function createUISectionBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UISectionBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUISectionBeginArray(instance);
  return createMessage(senderId, 2097157, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UISectionEnd {
}



export function readUISectionEndArray(instanceAsArray: any[]): UISectionEnd {
  const instance: UISectionEnd = {

  };

  return instance;
}

export function createUISectionEndArray(instance: UISectionEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUISectionEnd(raw: ArrayBuffer): UISectionEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUISectionEndArray(instanceAsArray);
}

export function createUISectionEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UISectionEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUISectionEndArray(instance);
  return createMessage(senderId, 2097158, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UISeparator {
  ElementId: number;
  Labels: string[];
  StyleId: string;
}



export function readUISeparatorArray(instanceAsArray: any[]): UISeparator {
  const instance: UISeparator = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2]
  };

  return instance;
}

export function createUISeparatorArray(instance: UISeparator): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId
  ];

  return instanceAsArray;
}

export function readUISeparator(raw: ArrayBuffer): UISeparator {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUISeparatorArray(instanceAsArray);
}

export function createUISeparator({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UISeparator;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUISeparatorArray(instance);
  return createMessage(senderId, 2097164, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStreamBegin {
  Category: string;
}



export function readUIStreamBeginArray(instanceAsArray: any[]): UIStreamBegin {
  const instance: UIStreamBegin = {
    Category: instanceAsArray[0]
  };

  return instance;
}

export function createUIStreamBeginArray(instance: UIStreamBegin): any[] {
  const instanceAsArray: any[] = [
    instance.Category
  ];

  return instanceAsArray;
}

export function readUIStreamBegin(raw: ArrayBuffer): UIStreamBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIStreamBeginArray(instanceAsArray);
}

export function createUIStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIStreamBeginArray(instance);
  return createMessage(senderId, 2097153, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStreamEnd {
}



export function readUIStreamEndArray(instanceAsArray: any[]): UIStreamEnd {
  const instance: UIStreamEnd = {

  };

  return instance;
}

export function createUIStreamEndArray(instance: UIStreamEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIStreamEnd(raw: ArrayBuffer): UIStreamEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIStreamEndArray(instanceAsArray);
}

export function createUIStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIStreamEndArray(instance);
  return createMessage(senderId, 2097154, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: UIStreamBegin;
}



export function readUIStreamStateArray(instanceAsArray: any[]): UIStreamState {
  const instance: UIStreamState = {
    StreamId: instanceAsArray[0],
    ClientSessionId: instanceAsArray[1],
    TrackId: instanceAsArray[2],
    Info: readUIStreamBeginArray(instanceAsArray[3])
  };

  return instance;
}

export function createUIStreamStateArray(instance: UIStreamState): any[] {
  const instanceAsArray: any[] = [
    instance.StreamId,
    instance.ClientSessionId,
    instance.TrackId,
    createUIStreamBeginArray(instance.Info)
  ];

  return instanceAsArray;
}

export function readUIStreamState(raw: ArrayBuffer): UIStreamState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIStreamStateArray(instanceAsArray);
}

export function createUIStreamState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIStreamState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIStreamStateArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStyles {
  Style: Record<string, string>;
}



export function readUIStylesArray(instanceAsArray: any[]): UIStyles {
  const instance: UIStyles = {
    Style: instanceAsArray[1]
  };

  return instance;
}

export function createUIStylesArray(instance: UIStyles): any[] {
  const instanceAsArray: any[] = [
    instance.Style
  ];

  return instanceAsArray;
}

export function readUIStyles(raw: ArrayBuffer): UIStyles {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIStylesArray(instanceAsArray);
}

export function createUIStyles({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIStyles;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIStylesArray(instance);
  return createMessage(senderId, 2097188, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIText {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  Text: string;
  Type: UITextType;
  Color: UIColor;
}



export function readUITextArray(instanceAsArray: any[]): UIText {
  const instance: UIText = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    Text: instanceAsArray[3],
    Type: instanceAsArray[4],
    Color: instanceAsArray[5]
  };

  return instance;
}

export function createUITextArray(instance: UIText): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.Text,
    instance.Type,
    instance.Color
  ];

  return instanceAsArray;
}

export function readUIText(raw: ArrayBuffer): UIText {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUITextArray(instanceAsArray);
}

export function createUIText({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIText;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUITextArray(instance);
  return createMessage(senderId, 2097162, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIUpdateBegin {
}



export function readUIUpdateBeginArray(instanceAsArray: any[]): UIUpdateBegin {
  const instance: UIUpdateBegin = {

  };

  return instance;
}

export function createUIUpdateBeginArray(instance: UIUpdateBegin): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIUpdateBegin(raw: ArrayBuffer): UIUpdateBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIUpdateBeginArray(instanceAsArray);
}

export function createUIUpdateBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIUpdateBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIUpdateBeginArray(instance);
  return createMessage(senderId, 2097182, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIUpdateEnd {
}



export function readUIUpdateEndArray(instanceAsArray: any[]): UIUpdateEnd {
  const instance: UIUpdateEnd = {

  };

  return instance;
}

export function createUIUpdateEndArray(instance: UIUpdateEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readUIUpdateEnd(raw: ArrayBuffer): UIUpdateEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIUpdateEndArray(instanceAsArray);
}

export function createUIUpdateEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIUpdateEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIUpdateEndArray(instance);
  return createMessage(senderId, 2097183, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIVegaChart {
  ElementId: number;
  Labels: string[];
  StyleId: string;
  DataJson: string;
  SpecJson: string;
}



export function readUIVegaChartArray(instanceAsArray: any[]): UIVegaChart {
  const instance: UIVegaChart = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
    StyleId: instanceAsArray[2],
    DataJson: instanceAsArray[3],
    SpecJson: instanceAsArray[4]
  };

  return instance;
}

export function createUIVegaChartArray(instance: UIVegaChart): any[] {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
    instance.StyleId,
    instance.DataJson,
    instance.SpecJson
  ];

  return instanceAsArray;
}

export function readUIVegaChart(raw: ArrayBuffer): UIVegaChart {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readUIVegaChartArray(instanceAsArray);
}

export function createUIVegaChart({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIVegaChart;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createUIVegaChartArray(instance);
  return createMessage(senderId, 2097172, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoFrame {
  Data: Uint8Array;
  FrameNumber: number;
  IsKey: boolean;
  TimestampInUs: bigint;
  DurationInUs: number;
}



export function readVideoFrameArray(instanceAsArray: any[]): VideoFrame {
  const instance: VideoFrame = {
    Data: instanceAsArray[0],
    FrameNumber: instanceAsArray[1],
    IsKey: instanceAsArray[2],
    TimestampInUs: instanceAsArray[3],
    DurationInUs: instanceAsArray[4]
  };

  return instance;
}

export function createVideoFrameArray(instance: VideoFrame): any[] {
  const instanceAsArray: any[] = [
    instance.Data,
    instance.FrameNumber,
    instance.IsKey,
    instance.TimestampInUs,
    instance.DurationInUs
  ];

  return instanceAsArray;
}

export function readVideoFrame(raw: ArrayBuffer): VideoFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readVideoFrameArray(instanceAsArray);
}

export function createVideoFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createVideoFrameArray(instance);
  return createMessage(senderId, 8388611, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoStreamBegin {
  Description: string;
  Codec: VideoCodec;
  Width: number;
  Height: number;
}



export function readVideoStreamBeginArray(instanceAsArray: any[]): VideoStreamBegin {
  const instance: VideoStreamBegin = {
    Description: instanceAsArray[0],
    Codec: instanceAsArray[1],
    Width: instanceAsArray[2],
    Height: instanceAsArray[3]
  };

  return instance;
}

export function createVideoStreamBeginArray(instance: VideoStreamBegin): any[] {
  const instanceAsArray: any[] = [
    instance.Description,
    instance.Codec,
    instance.Width,
    instance.Height
  ];

  return instanceAsArray;
}

export function readVideoStreamBegin(raw: ArrayBuffer): VideoStreamBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readVideoStreamBeginArray(instanceAsArray);
}

export function createVideoStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createVideoStreamBeginArray(instance);
  return createMessage(senderId, 8388609, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoStreamEnd {
}



export function readVideoStreamEndArray(instanceAsArray: any[]): VideoStreamEnd {
  const instance: VideoStreamEnd = {

  };

  return instance;
}

export function createVideoStreamEndArray(instance: VideoStreamEnd): any[] {
  const instanceAsArray: any[] = [

  ];

  return instanceAsArray;
}

export function readVideoStreamEnd(raw: ArrayBuffer): VideoStreamEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readVideoStreamEndArray(instanceAsArray);
}

export function createVideoStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createVideoStreamEndArray(instance);
  return createMessage(senderId, 8388610, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: VideoStreamBegin;
}



export function readVideoStreamStateArray(instanceAsArray: any[]): VideoStreamState {
  const instance: VideoStreamState = {
    StreamId: instanceAsArray[0],
    ClientSessionId: instanceAsArray[1],
    TrackId: instanceAsArray[2],
    Info: readVideoStreamBeginArray(instanceAsArray[3])
  };

  return instance;
}

export function createVideoStreamStateArray(instance: VideoStreamState): any[] {
  const instanceAsArray: any[] = [
    instance.StreamId,
    instance.ClientSessionId,
    instance.TrackId,
    createVideoStreamBeginArray(instance.Info)
  ];

  return instanceAsArray;
}

export function readVideoStreamState(raw: ArrayBuffer): VideoStreamState {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];
  return readVideoStreamStateArray(instanceAsArray);
}

export function createVideoStreamState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoStreamState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray = createVideoStreamStateArray(instance);
  return createMessage(senderId, 0, encode(instanceAsArray, {forceFloat32: true, useBigInt64: true}), 0, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

