import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectMenuContainerIds } from '../../shared/data/container.slice';

export function Menu() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [previousId, setPreviousId] = useState<string | null>(null);
  const rootContainerIds = useSelector(selectMenuContainerIds);

  useEffect(() => {
    if (rootContainerIds.length) {
      if (rootContainerIds.at(0) !== previousId) {
        containerRef.current?.scrollTo(0, 0);
      }
      setPreviousId(rootContainerIds.at(0));
    }
  }, [rootContainerIds, previousId]);

  if (!rootContainerIds?.length) return null;

  return (
    <Box
      sx={{
        width: '97px',
        height: '100dvh',
      }}
    >
      <Box
        ref={containerRef}
        className="ikon-scrollbar ikon-menu"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          p: 3,
          margin: 0,
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        }}
      >
        {rootContainerIds.map((rootContainerId) => (
          <UiContainer key={rootContainerId} containerId={rootContainerId} myMessage={false} />
        ))}
      </Box>
    </Box>
  );
}
