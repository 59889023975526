import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, InputBase, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../shared/api/api';
import { useSpace } from '../../shared/hooks/use-space';
import { iframeMessages } from '../../shared/utils/iframe.utils';
import { selectChatInput, selectChatUsersTyping, selectIsBlockingAction } from './chat.slice';
import { useUserInputOptions } from './use-user-input-options';

export function ChatInput(props: {
  sendMessage: (text: string, isCompleted: boolean) => void;
  sendFile: (file: File) => void;
  setMicrophone: (enabled: boolean) => void;
  enablePushToTalk: boolean;
  controlPosition: string;
}) {
  const userInputOptions = useUserInputOptions();
  const inputValueFromState = useSelector(selectChatInput);
  const isBlockingAction = useSelector(selectIsBlockingAction);
  const usersTyping = useSelector(selectChatUsersTyping);
  const [inputValue, setInputValue] = useState('');
  const [pulse, setPulse] = useState(false);
  const [microphoneEnabled, setMicrophoneEnabled] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const space = useSpace();
  const { data: profile, isLoading: profileLoading } = api.useGetProfileByUserQuery({ space: space.id, user: usersTyping.at(0) }, { skip: usersTyping.length !== 1 });

  const placeholder = useMemo(() => {
    if (microphoneEnabled) return 'Release to send';

    if (isBlockingAction) return 'Choose an option';

    if (usersTyping.length === 1 && !profileLoading) {
      if (profile) return `${profile.name || 'User'} is typing...`;
      return `User is typing...`;
    }

    if (usersTyping.length > 1) {
      return `Many users are typing...`;
    }

    return 'Send a message';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microphoneEnabled, usersTyping, profile]);

  useEffect(() => {
    setInputValue(inputValueFromState);
  }, [inputValueFromState]);

  function handleInput(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;

    setInputValue(text);
    props.sendMessage(text, false);
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  }

  function onBlur() {
    iframeMessages({ type: 'input-blur' });
  }

  function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    if (!inputValue) return;
    if (isBlockingAction) return;

    props.sendMessage(inputValue, true);
    setInputValue('');
    setPulse(true);
  }

  if (!userInputOptions.textInputEnabled && !userInputOptions.voiceInputEnabled) return null;

  const pushToTalkButton = (
    <IconButton
      type="button"
      title="Push to talk"
      disabled={isBlockingAction}
      disableRipple
      onMouseDown={() => {
        setMicrophoneEnabled(true);
        props.setMicrophone(true);
      }}
      onMouseUp={() => {
        setMicrophoneEnabled(false);
        props.setMicrophone(false);
      }}
      onTouchStart={() => {
        setMicrophoneEnabled(true);
        props.setMicrophone(true);
      }}
      onTouchEnd={() => {
        setMicrophoneEnabled(false);
        props.setMicrophone(false);
      }}
      sx={(theme) => ({
        width: { xs: '32px', md: '40px' },
        height: { xs: '32px', md: '40px' },
        padding: { xs: '7px', md: '10px' },
        color: theme.palette.onPrimary.highEmphasis,
        borderRadius: { xs: '10px', md: '12px' },
        background: theme.palette.mode === 'light' ? 'linear-gradient(135deg, #6E5EF6 2.5%, #0EB3CC 97.5%)' : 'linear-gradient(110deg, #9196FF 1.78%, #49D6EA 98.22%)',
        '&:disabled': {
          background: theme.palette.surface.background.disabled,
          color: theme.palette.onPrimary.disabled,
        },
      })}
    >
      <MicIcon />
    </IconButton>
  );

  return (
    <Box
      sx={{
        p: 0,
        width: userInputOptions.textInputEnabled ? '100%' : 'auto',
        maxWidth: '800px',
        margin: props.controlPosition === 'right' ? '0 0 0 auto' : props.controlPosition === 'left' ? '0 auto 0 0' : '0 auto',
      }}
    >
      <Box
        component="form"
        sx={{
          display: 'flex',
          gap: 1,
          p: { xs: 0.5, md: 1 },
          borderRadius: { xs: '14px', md: '20px' },
          backgroundColor: 'surface.background.input',
          backdropFilter: 'blur(24px)',
          boxShadow: '0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px 0px 8px -4px rgba(0, 0, 0, 0.03)',
          color: isBlockingAction ? 'rgba(255, 255, 255, 0.25)' : 'inherit',
        }}
        className={`ikon-chat-input ${pulse ? 'pulse' : ''}`}
        onSubmit={handleSubmit}
        onAnimationEnd={() => setPulse(false)}
      >
        {userInputOptions.textInputEnabled && (
          <InputBase
            className="ikon-chat-input-text"
            multiline
            maxRows={4}
            autoComplete="off"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInput}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            disabled={isBlockingAction}
            autoFocus={!isMobile}
            inputProps={{
              sx: {
                '&::placeholder': {
                  textAlign: microphoneEnabled ? 'right' : null,
                },
                fontSize: '16px',
                fontWeight: 400,
              },
            }}
            sx={{
              ml: 1,
              flex: 1,
            }}
          />
        )}

        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          {props.enablePushToTalk && userInputOptions.voiceInputEnabled && !inputValue ? (
            pushToTalkButton
          ) : (
            <IconButton
              type="submit"
              title="Send message"
              disabled={isBlockingAction}
              className={`ikon-chat-input-send ${inputValue ? '' : 'empty'}`}
              sx={(theme) => ({
                width: { xs: '32px', md: '40px' },
                height: { xs: '32px', md: '40px' },
                padding: { xs: '7px', md: '10px' },
                color: theme.palette.onPrimary.highEmphasis,
                borderRadius: { xs: '10px', md: '12px' },
                background: theme.palette.mode === 'light' ? 'linear-gradient(135deg, #6E5EF6 2.5%, #0EB3CC 97.5%)' : 'linear-gradient(110deg, #9196FF 1.78%, #49D6EA 98.22%)',
                // '&.empty': {
                //   background: 'linear-gradient(319deg, #575a99 17.18%, #2d808c 74.77%)',
                //   color: 'text.primary',
                // },
                '&:disabled': {
                  background: theme.palette.surface.background.disabled,
                  color: theme.palette.onPrimary.disabled,
                },
              })}
            >
              <SendIcon style={{ width: '18px', height: '18px' }} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
