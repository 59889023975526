import { UIBadgeVariant, UIButtonVariant, UIColor, UIIconType, UIInputVariant, UIVisibilityType } from '@ikon-web/event-shared';

interface UiElementBase {
  labels?: string[];
  styleId?: string;
}

export enum UiElementType {
  Header,
  Text,
  Badge,
  Image,
  Button,
  IconButton,
  File,
  Map,
  Separator,
  ListItem,
  Icon,
  MaterialSymbol,
  InputText,
  ProgressBar,
  VegaChart,
  Autocomplete,
  Checkbox,
  QS,
  UiElement,
}

export type UiElementHeaderLevel = 'large' | 'medium' | 'normal' | 'small';

export interface UiElementHeader extends UiElementBase {
  text: string;
  subText: string;
  level: UiElementHeaderLevel;
}

export type UiElementTextType = 'normal' | 'strong' | 'caption';

export interface UiElementText extends UiElementBase {
  text: string;
  type: UiElementTextType;
  color: UIColor;
}

export interface UiElementBadge extends UiElementBase {
  text: string;
  color: UIColor;
  variant: UIBadgeVariant;
  action?: string;
}

export interface UiElementImage extends UiElementBase {
  name: string;
  url: string;
  mime: string;
  actionId: string;
}

export interface UiElementButton extends UiElementBase {
  text: string;
  variant: UIButtonVariant;
  color: UIColor;
  action: string;
}

export interface UiElementIconButton {
  icon: UIIconType;
  action: string;
}

export interface UiElementIcon extends UiElementBase {
  icon: UIIconType;
  color: UIColor;
}

export interface UiElementMaterialSymbol extends UiElementBase {
  name: string;
  color: UIColor;
}

export interface UiElementListItem {
  name: string;
  text: string;
}

export interface UiElementFile {
  name: string;
}

export interface UiElementMapMarker {
  title: string;
  latitude: number;
  longitude: number;
}

export interface UiElementMap extends UiElementBase {
  name: string;
  marker: UiElementMapMarker;
  markers: UiElementMapMarker[];
}

export interface UiElementInputText extends UiElementBase {
  name: string;
  actionId: string;
  variant: UIInputVariant;
  color: UIColor;
}

export interface UiElementProgressBar extends UiElementBase {
  percentage: number;
}

export interface UiElementVegaChart extends UiElementBase {
  data: any;
  spec: any;
}

export interface UiAutocomplete extends UiElementBase {
  name: string;
  options: { label: string; id: string }[];
  actionId: string;
  minCount: number;
  maxCount: number;
  color: UIColor;
  variant: UIInputVariant;
}

export interface UiCheckbox extends UiElementBase {
  name: string;
  actionId: string;
  selected: boolean;
}

export interface UiElementQS extends UiElementBase {
  name: string;
  actionId: string;
  arguments: Record<string, any>;
}

export interface UiElement extends UiElementBase {
  name: string;
  description: string;
  arguments: any;
  actions: Record<string, { description: string; action: string }>;
  payloads: Record<string, any>;
}

export interface UiElementWrapper {
  type: UiElementType;
  element?:
    | UiElementHeader
    | UiElementText
    | UiElementBadge
    | UiElementImage
    | UiElementButton
    | UiElementIconButton
    | UiElementIcon
    | UiElementMaterialSymbol
    | UiElementFile
    | UiElementMap
    | UiElementListItem
    | UiElementInputText
    | UiElementProgressBar
    | UiElementVegaChart
    | UiAutocomplete
    | UiCheckbox
    | UiElementQS
    | UiElement;
  elementId?: number;
}

export interface UiContainerModel {
  type: 'container' | 'card' | 'column' | 'row' | 'right' | 'file-upload' | 'ordered-list' | 'unordered-list' | 'definition-list' | 'carousel' | 'button';
  elements: UiContainerOrElement[];
  gap?: number;
  parent?: UiContainerModel;
  labels?: string[];
  color?: UIColor;
  variant?: any;
  category?: string;
  containerId?: string;
  userId?: string;
  actionId?: string;
  allowedMimeTypes?: string[];
  maxSize?: number;
  isUpdate?: boolean;
  isHistory?: boolean;
  groupId?: string;
  sortingId?: number;
  isStable?: boolean;
  visibility?: UIVisibilityType;
  createdAt?: string;
  preciseCreatedAt?: bigint;
  styleId?: string;
}

export type UiContainerOrElement = UiElementWrapper | UiContainerModel;
