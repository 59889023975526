import * as Sentry from '@sentry/react';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authLogin, selectAuthState, setAuthError, setAuthInitialized } from '../../features/auth/auth.slice';
import { resetChat } from '../../features/chat/chat.slice';
import { resetDebugOverlay } from '../../features/debug-overlay/debug-overlay.slice';
import { resetProfile } from '../../features/profile/current-profile.slice';
import { resetRoom } from '../../features/room/room.slice';
import { resetSecondScreenContainer } from '../../features/second-screen/second-screen-containers.slice';
import { api } from '../api/api';
import { authApi } from '../api/auth-api';
import { LOCAL_STORAGE_AUTH_TOKEN } from '../constants';
import { resetContainer } from '../data/container.slice';
import { selectEmbeddedSpace } from '../data/embedded-space.slice';
import { resetRoomStack } from '../data/room-stack.slice';
import { resetSidebar } from '../data/sidebar.slice';
import { useSpace } from '../hooks/use-space';
import { getDeviceId } from '../services/device-id';
import { baseUrl } from '../utils/api.utils';
import { iframeMessages, isIframe } from '../utils/iframe.utils';
import { AuthContext, AuthContextInterface } from './auth.context';

export function AuthProvider(opts: { children?: ReactNode }) {
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const embeddedSpace = useSelector(selectEmbeddedSpace);
  const space = useSpace();

  const [getCurrentUser] = authApi.useLazyGetCurrentUserQuery({});
  const [authenticateAnonymous] = authApi.useAuthenticateAnonymousMutation();
  const [authenticateUsername] = authApi.useAuthenticateUsernameMutation();

  function reset() {
    console.log('[Meet] Resetting state');
    dispatch(resetChat());
    dispatch(resetContainer());
    dispatch(resetProfile());
    dispatch(resetDebugOverlay());
    dispatch(resetRoom());
    dispatch(resetRoomStack());
    dispatch(resetSecondScreenContainer());
    dispatch(resetSidebar());
    dispatch(api.util.resetApiState());
  }

  useEffect(() => {
    // Receive messages from parent window
    window.addEventListener('message', async (event) => {
      if (event.data.type === 'ikon') {
        const { type, data } = event.data.data;
        if (type === 'session') {
          if (data.session) localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, data.session);

          const response = await getCurrentUser().unwrap();

          if (authState.user && authState.user.id !== response.id) reset();
          dispatch(setAuthInitialized({ user: response }));
          Sentry.setUser({ id: response.id, username: response.name });
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuthentication = useCallback(async () => {
    if (embeddedSpace) {
      iframeMessages({ type: 'session' });
      return;
    }

    try {
      const response = await getCurrentUser().unwrap();

      if (authState.user && authState.user.id !== response.id) reset();
      dispatch(setAuthInitialized({ user: response }));
      Sentry.setUser({ id: response.id, username: response.name });
    } catch {
      if (space.allowAnonymousUsers) {
        try {
          const response = await authenticateAnonymous({ deviceId: getDeviceId('anonymous'), space: space.id }).unwrap();
          if (isIframe && response.token) localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, response.token);
          if (authState.user && authState.user.id !== response.user.id) reset();
          dispatch(setAuthInitialized({ user: response.user }));
          Sentry.setUser({ id: response.user.id, username: response.user.name });
        } catch {
          dispatch(setAuthInitialized({}));
        }
      } else {
        dispatch(setAuthInitialized({}));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticateAnonymous, dispatch, getCurrentUser, authState, space]);

  const loginWithProvider = useCallback((provider: string) => {
    window.location.replace(`${baseUrl}/meet/1/auth/${provider}`);
  }, []);

  const login = useCallback(
    async (data: { username; password }): Promise<void> => {
      try {
        const response = await authenticateUsername(data).unwrap();
        dispatch(authLogin({ user: response }));
        Sentry.setUser({ id: response.id, username: response.name });
      } catch (err) {
        console.error('[Meet] Login failed', err);
        dispatch(setAuthError({ error: err }));
      }
    },
    [authenticateUsername, dispatch],
  );

  const logoutCallback = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
    const params = new URLSearchParams({ redirect: window.location.origin });
    const url = `${baseUrl}/meet/1/auth/logout?${params.toString()}`;
    console.log(`[Meet] Logout ${url}`);
    window.location.replace(url);

    dispatch(api.util.resetApiState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (space && !authState.isAuthenticated) loadAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space]);

  const contextValue = useMemo(() => {
    return {
      ...authState,
      loginProvider: (provider) => loginWithProvider(provider),
      loginUsername: (username, password) => login({ username, password }),
      logout: logoutCallback,
      loadAuthentication,
    } as AuthContextInterface;
  }, [authState, login, loginWithProvider, logoutCallback, loadAuthentication]);

  return <AuthContext.Provider value={contextValue}>{opts.children}</AuthContext.Provider>;
}
