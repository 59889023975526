import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: {
  loaded: boolean;
  host?: string;
  id?: string;
  embeddedSpaceTemplate?: string;
  layoutMode?: string;
  theme?: string;
} = { loaded: false };

const interactionSlice = createSlice({
  name: 'embeddedSpace',
  initialState,
  reducers: {
    setEmbeddedSpace: (
      state,
      {
        payload: { id, embeddedSpaceTemplate, layoutMode, theme, host },
      }: PayloadAction<{
        id: string;
        embeddedSpaceTemplate: string;
        layoutMode?: string;
        theme?: string;
        host?: string;
      }>,
    ) => {
      state.loaded = true;
      state.id = id;
      state.embeddedSpaceTemplate = embeddedSpaceTemplate;
      state.layoutMode = layoutMode;
      state.theme = theme;
      state.host = host;
    },
    reset: () => initialState,
  },
});

export const embeddedSpaceReducer = interactionSlice.reducer;

export const { setEmbeddedSpace, reset: resetEmbeddedSpace } = interactionSlice.actions;

export const selectEmbeddedSpaceState = (state: RootState) => state.embeddedSpace;
export const selectEmbeddedSpace = createSelector(selectEmbeddedSpaceState, (state) => state.id);
export const selectEmbeddedSpaceTemplate = createSelector(selectEmbeddedSpaceState, (state) => state.embeddedSpaceTemplate);
export const selectEmbeddedSpaceTheme = createSelector(selectEmbeddedSpaceState, (state) => state.theme);
